import React, { useCallback, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';
import { isFunction } from 'lodash-es';

import { searchItems } from '@shared/utils';
import { FilterControlInterface, FilterValueInterface } from '../filter-control';
import { Icon } from '../icon';
import { ListItemList, ListPanel, ListSearch } from './partials';
import type { ListInterface } from './types';
import './styles/list.scss';

export interface ListProps
  extends ListInterface,
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  nested?: boolean;
  scrollable?: boolean;
  searchIcon?: string;
  useOnDemandSearch?: boolean;
  useVirtualList?: boolean;
  filterControls?: FilterControlInterface[];
}

/**
 * A generic iterator for displaying a list of items.
 */
export const List: React.FC<ListProps> = ({
  listTitle,
  listSubtitle,
  items = [],
  searchKeys = [],
  searchPlaceholder = '',
  searchIcon = 'search',
  noRecordMessage = 'No items found.',
  useOnDemandSearch = false,
  nested = false,
  scrollable = true,
  filterControls = [],
  useVirtualList = false,
  className,
  ...wrapperProps
}: ListProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [activeFilterValues, setActiveFilterValues] = useState<FilterValueInterface>({});

  const filteredItems = useMemo(() => {
    if (useOnDemandSearch && searchValue.length === 0) return [];

    return searchItems(items, {
      searchValue,
      searchKeys,
      filterValues: activeFilterValues,
      filterKeys: filterControls.reduce(
        (final, control) => ({
          ...final,
          [control.slug]: control.searchKeys || control.slug
        }),
        {}
      )
    });
  }, [searchValue, searchKeys, activeFilterValues, filterControls, items, useOnDemandSearch]);

  const handleSetSearchValue = useCallback(
    (value: string) => {
      setSearchValue(value);
    },
    [setSearchValue]
  );

  const handleLinkAction = useCallback(
    (onClick, event) => {
      if (onClick && isFunction(onClick)) {
        setSearchValue('');
        onClick(event);
      }
    },
    [setSearchValue]
  );

  return (
    <div
      className={classnames(
        'list',
        {
          'list--panel-open': showFilterPanel,
          'list--nested': nested,
          'list--scrollable': scrollable
          // 'list--has-panel': filterControls.length > 0
        },
        className
      )}
      {...wrapperProps}
    >
      {listTitle && <div className="h4 list__title">{listTitle}</div>}
      {listSubtitle && <div className="h6 text-muted list__subtitle">{listSubtitle}</div>}
      {searchKeys.length > 0 && (
        <div
          className={classnames('list__search', {
            'mb-3': !nested
          })}
        >
          <ListSearch
            value={searchValue}
            setSearchValue={handleSetSearchValue}
            placeholder={searchPlaceholder}
            searchIcon={searchIcon}
          />
          {filterControls.length > 0 && (
            <Button
              size="sm"
              variant="outline-dark"
              className={classnames({
                'ml-3': !nested
              })}
              onClick={event => {
                event.preventDefault();
                setShowFilterPanel(true);
              }}
            >
              <Icon name="settings" />
            </Button>
          )}
        </div>
      )}
      <div className="list__items" style={{zIndex: 10}}>
        <ListItemList
          items={filteredItems}
          hasNoMatches={filteredItems.length === 0 && searchValue.length > 0}
          hasNoRecords={filteredItems.length === 0 && !searchValue.length && !useOnDemandSearch}
          {...{ nested, noRecordMessage, useVirtualList, handleLinkAction }}
        />
      </div>
      {filterControls.length > 0 && (
        <div className="list__panel" style={{zIndex: 11}}>
          <ListPanel
            {...{
              setShowFilterPanel,
              searchValue,
              setSearchValue: handleSetSearchValue,
              activeFilterValues,
              setActiveFilterValues,
              filterControls
            }}
          />
        </div>
      )}
    </div>
  );
};
