import React, { isValidElement, useCallback } from 'react';
import classnames from 'classnames';
import { random, has, isFunction, isString, isObject, isNull, isUndefined } from 'lodash-es';
import { Button, Image } from 'react-bootstrap';

import { Avatar, AvatarProps } from '@components/common/avatar';
import { Icon } from '@components/common/icon';
import type { ListItemInterface, ListItemActionInterface } from '../types';
import '../styles/list-item.scss';

export interface ListItemProps extends ListItemInterface {
  collapsible?: boolean;
}

const isAvatar = (obj: unknown): obj is AvatarProps => {
  if (!isObject(obj)) return false;
  return has(obj, 'url') || has(obj, 'initials');
};

const isElement = (obj: unknown): obj is JSX.Element => {
  if (!isObject(obj) || isNull(obj) || isUndefined(obj)) return false;
  return isValidElement(obj);
};

export const ListItem = ({
  title,
  subtitle,
  image,
  label,
  actions,
  isActive,
  wrapperClassName,
  render
}: ListItemProps): JSX.Element => {
  const shortenText = useCallback((title: string, length: number): string => {
    return title
      ? title.length > length
        ? title.substring(0, length) + '...'
        : title
      : '';
  }, []);

  return (
    <div
      className={classnames(
        'd-flex align-items-start list-item',
        {
          'list-item--custom-render': isFunction(render)
        },
        wrapperClassName
      )}
    >
      {render ? (
        render()
      ) : (
        <>
          {image && (
            <div className="mr-3 mb-0 list-item__image">
              {isElement(image) && image}
              {isAvatar(image) && <Avatar {...image} />}
              {isString(image) && <Image src={image} className="d-block w-100" roundedCircle />}
            </div>
          )}
          <div className="mr-auto list-item__contents">
            <div className="list-item-contents">
              {label && (
                <div className="list-item-contents__label" title={label}>
                  {label}
                </div>
              )}
              <div className="list-item-contents__title" title={title}>
                {shortenText(title, 35)}
              </div>
              {subtitle && (
                <div className="list-item-contents__subtitle" title={subtitle}>
                  {shortenText(subtitle, 45)}
                </div>
              )}
            </div>
          </div>
          {actions && (
            <div className="d-flex align-self-start list-item__actions">
              {actions.map((action: JSX.Element | ListItemActionInterface) => {
                if (isElement(action)) return action;

                return (
                  <Button
                    key={`ListItemAction_${random(0, 20000)}`}
                    className="list-item-action"
                    size="sm"
                    variant="outline-primary"
                    onClick={action.action}
                  >
                    {action.label}
                  </Button>
                );
              })}
            </div>
          )}
        </>
      )}
      {isActive && (
        <div className="list-item__active-marker">
          <Icon name="chevron-right" size="medium" />
        </div>
      )}
    </div>
  );
};
