import readyToBillStatuses from 'public/readyToBillStatusV2.json';
import { DetailedJob } from '@shared/types';
import { jobStatus, jobStatusLabels } from '@constants';

export const getJobStatus = (job: DetailedJob): string => {
  const { status, readyToBillStatus } = job;

  let statusName = jobStatusLabels?.[status] ?? '';

  if (status === jobStatus.READY_TO_BILL) {
    const rtb = readyToBillStatuses.find(s => s.id === readyToBillStatus);
    statusName = rtb?.name ?? statusName;
  }

  return statusName;
};
