import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';

import { request } from '@shared/api';
import {
  NewSchedule as NewScheduleInterface,
  DetailedSchedule as ScheduleInterface,
  DetailedJobAssignment as JobAssignmentInterface,
  CrewListTemplate as CrewListTemplateInterface,
  DetailedCrewListTemplate as DetailedCrewListTemplateInterface,
  PaginatedData,
  PagePrams,
  ListedJob
} from '@shared/types';
import { createPageParams, queryStringify } from '@shared/utils';

export const getSchedule = createAsyncThunk(
  'schedule/getSchedules',
  async (date: Dayjs = dayjs()) => {
    try {
      const response = await request<ScheduleInterface>(
        'get',
        `schedules/${date.format('YYYY-MM-DD')}`
      );
      return response;
    } catch (err) {
      if (err.status === 404) {
        return null; // 404 is not an error status for getSchedules
      }
      throw err;
    }
  }
);

export const createSchedule = createAsyncThunk(
  'schedule/createSchedule',
  async ({
    schedule,
    division
  }: {
    schedule: NewScheduleInterface;
    division: string | undefined;
  }) => {
    const withDivision = division ? `?division=${division}` : '';
    const response = await request<ScheduleInterface, { schedule: NewScheduleInterface }>(
      'post',
      `schedules${withDivision}`,
      {
        schedule
      }
    );

    return response;
  }
);

export const updateSchedule = createAsyncThunk(
  'schedule/updateSchedule',
  async ({
    schedule,
    division
  }: {
    schedule: NewScheduleInterface;
    division: string | undefined;
  }) => {
    const withDivision = division ? `?division=${division}` : '';
    const response = await request<ScheduleInterface, { schedule: ScheduleInterface }>(
      'patch',
      `schedules/${schedule.id}${withDivision}`,
      {
        schedule
      }
    );

    return response;
  }
);

export const getCrewListTemplates = createAsyncThunk('schedule/getCrewListTemplates', async () => {
  const pageParams = createPageParams({ number: 1, size: 100000 });
  const response = await request<PaginatedData<CrewListTemplateInterface>>(
    'get',
    `crew_list_templates?${pageParams}`
  );

  return response;
});

export const getCrewListTemplate = createAsyncThunk(
  'schedule/getCrewListTemplate',
  async (id: number) => {
    const response = await request<DetailedCrewListTemplateInterface>(
      'get',
      `crew_list_templates/${id}`
    );

    return response;
  }
);

type NewCrewListTemplate = Omit<
  DetailedCrewListTemplateInterface,
  'id' | 'createdAt' | 'updatedAt'
>;

export const createCrewListTemplate = createAsyncThunk(
  'schedule/createCrewListTemplate',
  async (crewListTemplate: NewCrewListTemplate) => {
    const response = await request<
      DetailedCrewListTemplateInterface,
      { crewListTemplate: NewCrewListTemplate }
    >('post', 'crew_list_templates', { crewListTemplate });

    return response;
  }
);

export const updateCrewListTemplate = createAsyncThunk(
  'schedule/updateCrewListTemplate',
  async (crewListTemplate: DetailedCrewListTemplateInterface) => {
    const response = await request<
      DetailedCrewListTemplateInterface,
      { crewListTemplate: DetailedCrewListTemplateInterface }
    >('patch', `crew_list_templates/${crewListTemplate.id}`, { crewListTemplate });

    return response;
  }
);

export const deleteCrewListTemplate = createAsyncThunk(
  'schedule/deleteCrewListTemplate',
  async (id: number) => {
    return await request<unknown>('delete', `crew_list_templates/${id}`);
  }
);

export const getJobAssignments = createAsyncThunk(
  'schedule/getJobAssignments',
  async (date?: string) => {
    const pageParams = createPageParams({ number: 1, size: 100000 });
    const dateParam = `date=${dayjs(date).format('YYYY-MM-DD')}`;

    const response = await request<PaginatedData<JobAssignmentInterface>>(
      'get',
      `job_assignments?${pageParams}&${dateParam}`
    );

    return response;
  }
);

export const getSuggestedSuperintendentNames = createAsyncThunk(
  'schedules/suggested_superintendent_names',
  async () => {
    return await request<string[]>('get', 'schedules/suggested_superintendent_names');
  }
);

export const getScheduleJobs = createAsyncThunk(
  'schedule/getScheduleJobs',
  async ({ page, filter }: { page: PagePrams; filter?: Record<string, unknown> }) => {
    return await request<PaginatedData<ListedJob>>(
      'get',
      `jobs/scheduleable?${queryStringify({ page, filter })}`
    );
  }
);
