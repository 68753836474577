import React, { FunctionComponent } from 'react';
import { Form } from 'react-bootstrap';

type BSValidationState = 'success' | 'warning' | 'error' | null;

export interface WithFormGroupProps {
  attribute: string;
  title?: string;
  label?: string | JSX.Element;
  showLabel?: boolean;
  className?: string;
  validationState?: BSValidationState;
}

export const withFormGroup = (formField: JSX.Element): FunctionComponent<WithFormGroupProps> => {
  return ({
    title,
    label,
    attribute,
    showLabel,
    className,
  }: WithFormGroupProps) => {
    return (
      <Form.Group controlId={attribute} className={className}>
        {showLabel && <Form.Label>{title || label}</Form.Label>}
        {formField}
      </Form.Group>
    );
  };
};
