import { Ability } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { RootState, CurrentUserState } from '@shared/store';
import React, { createContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { abilityFor } from './ability';

export const AbilityContext = createContext<Ability>(abilityFor(undefined));

export const AbilityProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { isLoggedIn, currentUser } = useSelector<RootState, CurrentUserState>(
    state => state.currentUser
  );
  const ability = useMemo(() => {
    return abilityFor(isLoggedIn ? currentUser : undefined);
  }, [isLoggedIn, currentUser]);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export const Can = createContextualCan(AbilityContext.Consumer);
