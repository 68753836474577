import React from 'react';
import classnames from 'classnames';

import './styles/page-header.scss';

export interface PageHeaderProps extends React.HTMLAttributes<HTMLElement> {
  /** The title of the page. */
  title: string;
  /** An optional subtitle to display below the main title. */
  subtitle?: string;
  /** Additional controls or content to render on the right edge of the header. */
  actions?: React.ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  actions,
  className = '',
  ...props
}) => (
  <div className={classnames('page-header', className)} {...props}>
    <h1 className="page-header__title">
      {title}
      {subtitle && <small>{subtitle}</small>}
    </h1>
    {actions && <div className="page-header__actions">{actions}</div>}
  </div>
);
