const AUTH_TOKEN = '__libs_session_token__';
const LOGIN_REDIRECT = '__libs_login_redirect';

export const SessionService = {
  hasSession(): boolean {
    return this.getToken() !== null;
  },
  getToken(): string | null {
    return window.localStorage.getItem(AUTH_TOKEN);
  },
  setToken(token: string): void {
    window.localStorage.setItem(AUTH_TOKEN, token);
  },
  clearSession(): void {
    window.localStorage.removeItem(AUTH_TOKEN);
  },
  get loginRedirect(): string | null {
    return window.localStorage.getItem(LOGIN_REDIRECT);
  },
  set loginRedirect(path: string | null) {
    if (path == null) {
      window.localStorage.removeItem(LOGIN_REDIRECT);
    } else {
      window.localStorage.setItem(LOGIN_REDIRECT, path);
    }
  }
};
