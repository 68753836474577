import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Form } from 'react-bootstrap';
import { FieldRenderProps } from 'react-final-form';

dayjs.extend(customParseFormat);

const ensureValidDate = (date: Dayjs, fallbackValue: Dayjs) => {
  return date.isValid() ? date : fallbackValue;
};

interface TimePickerControlProps {
  value?: Date;
  defaultTime?: Date;
  onChange?: (date?: Date) => void;
  isDisabled?: boolean;
  size?: 'sm' | 'lg';
  className?: string;
}

const TimePickerControl: React.FC<TimePickerControlProps> = ({
  value,
  defaultTime,
  onChange,
  isDisabled,
  size,
  className
}) => {
  const defaultDate = ensureValidDate(dayjs(defaultTime), dayjs());
  const inputDate = ensureValidDate(dayjs(value), defaultDate);

  const [hourValue, setHourValue] = useState(() => inputDate.format('h'));
  const [minuteValue, setMinuteValue] = useState(() => inputDate.format('mm'));
  const [periodValue, setPeriodValue] = useState(() => inputDate.format('A'));

  const handleHourValueChange = useCallback(
    ({ target: { value } }) => {
      if (!value.length && hourValue.length) setHourValue(value);
      const valueNumber = Number(value);
      if (valueNumber > 0 && valueNumber <= 12) setHourValue(value);
    },
    [hourValue, setHourValue]
  );

  const handleMinuteValueChange = useCallback(
    ({ target: { value } }) => {
      if (!value.length && minuteValue.length) setMinuteValue(value);
      const valueNumber = Number(value);
      if (valueNumber >= 0 && valueNumber < 60) setMinuteValue(value);
    },
    [minuteValue, setMinuteValue]
  );

  const handleTimePeriodChange = useCallback(
    ({ target }) => {
      setPeriodValue(target.value);
    },
    [setPeriodValue]
  );

  useEffect(() => {
    if (hourValue.length && minuteValue.length) {
      const updatedDate = dayjs(
        `${inputDate.format('YYYY-MM-DD')} ${Number(hourValue)}:${Number(
          minuteValue
        )} ${periodValue}`,
        'YYYY-MM-DD h:m A'
      );
      onChange?.(updatedDate.isValid() ? updatedDate.toDate() : undefined);
    }
  }, [hourValue, minuteValue, periodValue, inputDate, onChange]);

  return (
    <div className={classnames('d-flex', className)}>
      <Form.Control
        size={size}
        value={hourValue}
        onChange={handleHourValueChange}
        disabled={isDisabled}
        className="border-right-0 pr-0 text-right"
        style={{ width: 30 }}
      />
      <Form.Control
        size={size}
        as="span"
        plaintext
        className={classnames('border-dark text-center px-1 d-flex align-items-center', {
          'bg-white': !isDisabled,
          'bg-light': isDisabled,
          // For some reason sized based class was not automatically
          // being applied when in plaintext mode
          [`form-control-${size}`]: size
        })}
        style={{ width: 15 }}
      >
        :
      </Form.Control>
      <Form.Control
        size={size}
        value={minuteValue}
        disabled={isDisabled}
        onChange={handleMinuteValueChange}
        className="border-left-0 pl-0"
        maxLength={2}
        style={{ width: 30 }}
      />
      <Form.Control
        size={size}
        as="select"
        value={periodValue}
        onChange={handleTimePeriodChange}
        disabled={isDisabled}
        className="w-auto"
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </Form.Control>
    </div>
  );
};

export interface TimePickerFieldProps
  extends TimePickerControlProps,
    FieldRenderProps<string, HTMLElement> {
  label?: string;
}

export const TimePickerField = ({
  label,
  className,
  input,
  meta,
  ...timePickerProps
}: TimePickerFieldProps): JSX.Element => {
  const value = useMemo(() => dayjs(input.value).toDate(), [input.value]);
  const handleOnChange = useCallback(
    (date?: Date) => {
      input.onChange(date?.toISOString() ?? '');
    },
    [input]
  );
  return label ? (
    <Form.Group className={classnames('time-picker-field', className)} controlId={input.name}>
      <Form.Label>{label}</Form.Label>
      <TimePickerControl {...timePickerProps} value={value} onChange={handleOnChange} />
      {meta.error && (
        <Form.Control.Feedback type="invalid" className="d-inline">
          {meta.error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  ) : (
    <TimePickerControl
      className={classnames('time-picker-field mb-2', className)}
      {...timePickerProps}
      value={value}
      onChange={handleOnChange}
    />
  );
};
