import React from 'react';
import { InputGroup, Form } from 'react-bootstrap';

import { Icon } from '@components/common';

export interface ListSearchProps {
  value: string;
  setSearchValue: (value: string) => void;
  searchIcon?: string;
  placeholder: string;
}

export const ListSearch = ({
  setSearchValue,
  searchIcon = 'search',
  ...props
}: ListSearchProps): JSX.Element => (
  <InputGroup>
    <InputGroup.Prepend>
      <InputGroup.Text>
        <Icon name={searchIcon} />
      </InputGroup.Text>
    </InputGroup.Prepend>
    <Form.Control onChange={event => setSearchValue(event.target.value)} {...props} />
  </InputGroup>
);
