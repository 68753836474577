import { ACCEPTED_IMAGE_TYPES, MAX_PREVIEW_SIZE, MAX_UPLOAD_SIZE } from '@shared/constants';

interface FallbackSvgPrams {
  width: number;
  height: number;
  text: string;
}

const createFallbackSvg = ({ width, height, text }: FallbackSvgPrams) => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">
<rect fill="#ddd" width="${width}" height="${height}"/>
<text fill="rgba(0,0,0,0.5)" font-family="sans-serif" font-size="12" dy="0" font-weight="bold" x="50%" y="50%" text-anchor="middle">${text}</text>
</svg>`;

  const cleaned = svg
    .replace(/[\t\n\r]/gim, '') // Strip newlines and tabs
    .replace(/\s\s+/g, ' ') // Condense multiple spaces
    .replace(/'/gim, '\\i'); // Normalize quotes

  const encoded = encodeURIComponent(cleaned)
    .replace(/\(/g, '%28') // Encode brackets
    .replace(/\)/g, '%29');

  return `data:image/svg+xml;charset=UTF-8,${encoded}`;
};

export const createPreviewImage = (
  file: File,
  fallbackSvgPrams: FallbackSvgPrams = { width: 100, height: 100, text: file.name }
): Promise<string> => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    if (!ACCEPTED_IMAGE_TYPES.includes(file.type)) {
      return reject(
        new Error(
          `Invalid format. Acceptable formats are ${ACCEPTED_IMAGE_TYPES.map(imageType =>
            imageType.replace('image/', '').toUpperCase()
          ).join(', ')}`
        )
      );
    }

    if (file.size > MAX_UPLOAD_SIZE) {
      return reject(Error(`File is too large. Max file size is ${MAX_UPLOAD_SIZE / 1000000}MB`));
    }

    if (file.size > MAX_PREVIEW_SIZE) {
      return resolve(createFallbackSvg(fallbackSvgPrams));
    }

    fileReader.onloadend = () => resolve(fileReader.result as string);
    fileReader.readAsDataURL(file);
  });
};
