import React from 'react';
import { Icon } from '@components/common';

export const Loading = (): JSX.Element => {
  return (
    <div className="d-flex h-100 w-100 align-items-center justify-content-center">
      <Icon name="truck" size="huge" animation="pulse" animationSpeed="medium" />
    </div>
  );
};
