import { DetailedMaterialType } from '@shared/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { groupBy } from 'lodash-es';

import { MaterialsState } from './types';
import { ServiceType } from '@shared/constants';
import { RootState } from '@shared/store';
import { useSelector } from 'react-redux';
import {
  getMaterialTypes,
  getMaterials,
  createMaterialType,
  updateMaterialType,
  deleteMaterialType
} from './thunks';

const initialState: MaterialsState = {
  materials: [],
  materialTypes: [],
  sortedMaterials: {},
  isLoadingMaterials: false,
  isLoadingMaterialTypes: false,
  isSubmitting: false,
  isLoading: true,
  isLoaded: false,
  isLoadedMaterials: false,
  isLoadedMaterialTypes: false,
  activeMaterialType: null,
  errorMessages: []
};

const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    setActiveMaterialType(state, action: PayloadAction<DetailedMaterialType>) {
      state.activeMaterialType = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getMaterials.pending, state => {
      state.isLoadingMaterials = true;
    });
    builder.addCase(getMaterials.fulfilled, (state, action) => {
      state.materials = action.payload.data;
      state.sortedMaterials = groupBy(
        action.payload.data,
        item => item.materialTypeName.toLowerCase() as ServiceType
      );
      state.isLoadingMaterials = false;
      state.isLoadedMaterials = true;
    });
    builder.addCase(getMaterials.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages.push(action.error.message);
      }
      state.isLoadingMaterials = false;
    });

    builder.addCase(getMaterialTypes.pending, state => {
      state.isLoadingMaterialTypes = true;
    });
    builder.addCase(getMaterialTypes.fulfilled, (state, action) => {
      state.materialTypes = action.payload.data;
      state.isLoadingMaterialTypes = false;
      state.isLoadedMaterialTypes = true;
    });
    builder.addCase(getMaterialTypes.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages.push(action.error.message);
      }
      state.isLoadingMaterialTypes = false;
    });

    builder.addCase(createMaterialType.pending, state => {
      state.isLoadingMaterialTypes = true;
      state.isLoadedMaterials = true;
    });
    builder.addCase(createMaterialType.fulfilled, (state, action) => {
      const { materials, ...materialType } = action.payload;

      state.materialTypes = state.materialTypes.concat([materialType]);
      state.materials = state.materials.concat(materials);

      state.sortedMaterials = {
        ...state.sortedMaterials,
        [action.payload.name.toLowerCase()]: materials
      };

      state.isLoadingMaterialTypes = false;
      state.isLoadedMaterials = false;
    });
    builder.addCase(createMaterialType.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages.push(action.error.message);
      }
      state.isLoadingMaterialTypes = false;
      state.isLoadedMaterials = false;
    });

    builder.addCase(updateMaterialType.pending, state => {
      state.isLoadedMaterials = true;
      state.isLoadingMaterialTypes = true;
    });
    builder.addCase(updateMaterialType.fulfilled, (state, action) => {
      const { materials, ...materialType } = action.payload;

      const materialTypeIndex = state.materialTypes.findIndex(({ id }) => id === materialType.id);

      if (materialTypeIndex !== -1) {
        state.materialTypes[materialTypeIndex] = materialType;
      }

      materials.forEach(material => {
        const materialIndex = state.materials.findIndex(
          stateMaterial => stateMaterial.id === material.id
        );

        if (materialIndex !== -1) {
          state.materials[materialIndex] = material;
        }
      });

      state.activeMaterialType = action.payload;
      state.isLoadedMaterials = false;
      state.isLoadingMaterialTypes = false;
    });
    builder.addCase(updateMaterialType.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages.push(action.error.message);
      }
      state.isLoadingMaterialTypes = false;
      state.isLoadedMaterials = false;
    });

    builder.addCase(deleteMaterialType.pending, state => {
      state.isSubmitting = true;
    });

    builder.addCase(deleteMaterialType.fulfilled, (state, { payload: didDelete }) => {
      const materialTypeIndex = state.materialTypes.findIndex(
        materialType => materialType.id === state.activeMaterialType?.id
      );
      const materialIndexes = state.materials
        .filter(({ materialTypeId }) => materialTypeId === state.activeMaterialType?.id)
        .map(material => state.materials.findIndex(m => m === material));

      if (materialTypeIndex >= 0) {
        state.materialTypes[materialTypeIndex].isDiscarded = didDelete;
        materialIndexes.forEach(index => {
          state.materials[index].isDiscarded = didDelete;
        });
      }

      state.sortedMaterials = groupBy(
        state.materials,
        item => item.materialTypeName.toLowerCase() as ServiceType
      );
      state.activeMaterialType = null;
      state.isSubmitting = false;
    });

    builder.addCase(deleteMaterialType.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }

      state.isSubmitting = false;
    });
  }
});

export const { setActiveMaterialType } = materialsSlice.actions;
export default materialsSlice.reducer;

export const useMaterialsSelector = (): MaterialsState =>
  useSelector<RootState, MaterialsState>(state => state.materials);
