export const hasLength = (obj: unknown): boolean => {
  if (obj === undefined) {
    return false;
  }
  if (obj === null) {
    return false;
  }
  if (obj instanceof Array) {
    return obj.length > 0;
  }
  if (typeof obj === 'string') {
    return obj.length > 0;
  }
  if (typeof obj === 'object') {
    return Object.keys(obj as Record<string, unknown>).length > 0;
  }

  return false;
};
