import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@shared/api';
import { PaginatedData, User as UserInterface } from '@shared/types';
import { createPageParams } from '@shared/utils';

import { UsersState } from './types';
import { RootState } from '@shared/store';
import { useSelector } from 'react-redux';

const initialState: UsersState = {
  allUsers: [],
  isLoaded: false,
  isLoading: false,
  isSubmitting: false,
  activeUser: null,
  errorMessages: []
};

export const getUsers = createAsyncThunk('users/getUsers', async () => {
  const response = await request<PaginatedData<UserInterface>>(
    'get',
    `users?${createPageParams({ number: 1, size: 100000 })}`
  );

  return response;
});

export const createUser = createAsyncThunk('users/createUser', async (user: UserInterface) => {
  const response = await request<UserInterface, { user: UserInterface }>('post', 'users', {
    user
  });

  return response;
});

export const updateUser = createAsyncThunk('users/updateUser', async (user: UserInterface) => {
  const response = await request<UserInterface, { user: UserInterface }>(
    'put',
    `users/${user.id}`,
    { user }
  );

  return response;
});

export const deleteUser = createAsyncThunk('users/deleteUser', async (user: UserInterface) => {
  return await request<unknown, { user: UserInterface }>('delete', `users/${user.id}`);
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    startSubmitUsers(state) {
      state.isSubmitting = true;
    },
    endSubmitUsers(state) {
      state.isSubmitting = false;
    },
    setActiveUser(state, action: PayloadAction<UserInterface | null>) {
      state.activeUser = action.payload;
    },
    startLoadUsers(state) {
      state.isLoading = true;
    },
    endLoadUsers(state) {
      state.isLoading = false;
    },
    setUsersError(state, action: PayloadAction<string[]>) {
      state.errorMessages = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      const { data, page } = action.payload;

      state.allUsers = data;
      state.page = page;
      state.isLoading = false;
      state.isLoaded = true;
    });

    builder.addCase(createUser.fulfilled, (state, action) => {
      state.allUsers = state.allUsers.concat([action.payload]);
      state.activeUser = action.payload;
      state.isSubmitting = false;
    });

    builder.addCase(createUser.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }
      state.isSubmitting = false;
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      const userIndex = state.allUsers.findIndex(user => user.id === action.payload.id);

      state.activeUser = null;

      if (userIndex !== -1) {
        state.allUsers[userIndex] = action.payload;
      }

      state.isSubmitting = false;
    });

    builder.addCase(updateUser.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }
      state.isSubmitting = false;
    });

    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.allUsers = state.allUsers.filter(user => user.id !== action.meta.arg.id);
      state.activeUser = null;
      state.isSubmitting = false;
    });

    builder.addCase(deleteUser.rejected, (state, action) => {
      state.errorMessages = action.error.message?.split('|') ?? [];
      state.isSubmitting = false;
    });
  }
});

export const {
  startSubmitUsers,
  endSubmitUsers,
  setActiveUser,
  startLoadUsers,
  endLoadUsers,
  setUsersError
} = usersSlice.actions;
export default usersSlice.reducer;

export const useUsersSelector = (): UsersState =>
  useSelector<RootState, UsersState>(state => state.users);
