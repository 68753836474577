import React, { useCallback, useMemo } from 'react';
import { Form, Button } from 'react-bootstrap';
import { TableState, UsePaginationInstanceProps, UsePaginationState } from 'react-table';

import { Icon } from '@components/common';

type ResourceGridPaginationProps<D extends object> = UsePaginationInstanceProps<D> & {
  state: TableState<D> & UsePaginationState<D>;
  totalRows: number;
  isFetchingData?: boolean;
};

const PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100, 1_000, 10_000];

function computePageRange(pageIndex: number, pageSize: number, totalRows: number) {
  const start = pageIndex * pageSize + 1;
  const end = Math.min(totalRows, (pageIndex + 1) * pageSize);
  return {
    start,
    end
  };
}

export const ResourceGridPagination = <D extends object>({
  canNextPage,
  canPreviousPage,
  previousPage,
  pageOptions,
  pageCount,
  nextPage,
  gotoPage,
  setPageSize,
  totalRows,
  isFetchingData,
  state: { pageIndex, pageSize }
}: ResourceGridPaginationProps<D>): JSX.Element | null => {
  const handleSetPageSize = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setPageSize(Number(target.value));
    },
    [setPageSize]
  );

  const pageRange = useMemo(() => computePageRange(pageIndex, pageSize, totalRows), [
    pageIndex,
    pageSize,
    totalRows
  ]);

  const { start, end } = pageRange;

  if (totalRows === 0) return null;

  return (
    <div
      className="d-flex align-items-center px-2 py-3 border-bottom mb-3"
      style={{
        opacity: isFetchingData ? 0.2 : 1,
        transition: 'opacity 0.125s ease'
      }}
    >
      <p className="mb-0 mr-auto">
        <strong>{start}</strong> to <strong>{end}</strong> of <strong>{totalRows}</strong>
      </p>
      <div className="d-flex align-items-center ml-auto mr-auto">
        <Button
          variant="link"
          className="text-dark"
          block
          onClick={previousPage}
          disabled={!canPreviousPage || isFetchingData}
        >
          <Icon name="chevron-left" />
        </Button>
        <Form.Control
          as="select"
          value={pageIndex + 1}
          className="w-auto"
          disabled={isFetchingData}
          onChange={({ target }) => gotoPage(Number(target.value) - 1)}
        >
          {pageOptions.map(option => (
            <option key={`goToPage_${option}`}>{option + 1}</option>
          ))}
        </Form.Control>
        <span className="ml-2 text-nowrap" style={{ position: 'relative', top: 1 }}>
          of {pageCount}
        </span>
        <Button
          variant="link"
          className="text-dark"
          block
          onClick={nextPage}
          disabled={!canNextPage || isFetchingData}
        >
          <Icon name="chevron-right" />
        </Button>
      </div>
      <div>
        <Form.Control
          as="select"
          value={pageSize}
          disabled={isFetchingData}
          onChange={handleSetPageSize}
        >
          {PAGE_SIZE_OPTIONS.map(option => (
            <option key={`PageSizeOption_${option}`} value={option}>
              {option} per page
            </option>
          ))}
        </Form.Control>
      </div>
    </div>
  );
};
