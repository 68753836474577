import { RootState } from "@shared/store/store";
import { useSelector } from "react-redux";
import { DivisionsState } from "./types";
import { Division } from "@shared/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getDivisions } from "./thunks";

const initialState: DivisionsState = {
    divisions: [],
    isLoading: true,
    isLoaded: false,
    isSubmitting: false,
    errorMessages: []
}

const divisionsSlice = createSlice({
    name: 'divisions',
    initialState,
    reducers: {
        setDivisions(state, action: PayloadAction<Division[]>) {
            state.divisions = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getDivisions.pending, state => {
            state.isLoading = true;
            state.isLoaded = false;
        });

        builder.addCase(getDivisions.fulfilled, (state, action) => {
            const data = action.payload

            state.divisions = data;
            state.isLoading = false;
            state.isLoaded = true;
        });
    }
});

export const {
    setDivisions
} = divisionsSlice.actions;

export default divisionsSlice.reducer;

export const useDivisionsSelector = (): DivisionsState =>
    useSelector<RootState, DivisionsState>(state => state.divisions)
