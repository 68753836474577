import React from 'react';
import classnames from 'classnames';

import './styles/icon.scss';

export type IconSize = 'xs' | 'small' | 'medium' | 'large' | 'huge';
export type IconAnimation = 'spin' | 'pulse' | 'throb' | 'beat';
export type IconAnimationSpeed = 'slow' | 'medium' | 'fast';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  /** The name of the icon to display. */
  name: string;
  /** The icon size. */
  size?: IconSize;
  /** Assigns an animation to the icon. */
  animation?: IconAnimation | null;
  /** Sets the speed of the animation. */
  animationSpeed?: IconAnimationSpeed | null;
  /** Reverses any animation effect. */
  animationIsReversed?: boolean;
}

/**
 * Wrapper component for rendering an icon. Accepts a name which should
 * match a file found in `app/assets/icons/src`.
 */
export const Icon = ({
  name,
  size = 'small',
  animation = null,
  animationSpeed = null,
  animationIsReversed = false,
  className: classNameProp,
  ...svgProps
}: IconProps): JSX.Element | null => {
  const ANIMATION_BASE = 'animation';
  const hasAnimation = animation !== null;
  const hasAnimationSpeed = animationSpeed !== null;

  const className = classnames(
    'icon',
    {
      [`icon--${size}`]: size,
      [`icon--${ANIMATION_BASE}`]: hasAnimation,
      [`icon--${ANIMATION_BASE}-${animation}`]: hasAnimation,
      [`icon--${ANIMATION_BASE}-${animationSpeed}`]: hasAnimation && hasAnimationSpeed,
      [`icon--${ANIMATION_BASE}-reverse`]: hasAnimation && animationIsReversed
    },
    classNameProp
  );

  return (
    <svg className={className} {...svgProps}>
      <use xlinkHref={`#${name}-icon`} />
    </svg>
  );
};
