import { isObject, get, last } from 'lodash-es';

type Segment = string | { index: number; segment: string };

interface ObjectPathsObject {
  segments: Segment[];
  createPath: (segments: Segment[], removeLastIndex: boolean) => Segment;
  parentIndex: number | null;
  parentPath: string;
  rootPath: string;
  parentObjectPath: string;
  objectIndex: number | null;
  objectType: unknown;
}

export const createObjectPaths = (fieldName: string): ObjectPathsObject => ({
  segments: fieldName.split('.').map<Segment>(segment => {
    const index = segment.match(/\d+/);

    if (index) {
      return {
        index: Number(index[0]),
        segment: segment.replace(/\[\d+\]/, '')
      };
    }

    return segment;
  }),
  createPath(segments: Segment[], removeLastIndex = false) {
    return segments.reduce((final: string, segment, index, allSegments) => {
      const isLastSegment = index === allSegments.length - 1;
      const endPunc = isLastSegment ? '' : '.';

      if (!isObject(segment)) return `${final}${segment}${endPunc}`;
      if (isLastSegment && removeLastIndex) return `${final}${segment.segment}`;
      return `${final}${segment.segment}[${segment.index}]${endPunc}`;
    }, '');
  },
  get parentIndex() {
    const parentSegment: Segment | undefined = this.segments[this.segments.length - 2];
    if (!parentSegment || !isObject(parentSegment)) return null;
    return parentSegment.index;
  },
  get parentPath() {
    return this.createPath(this.segments.slice(0, -1), true);
  },
  get parentObjectPath() {
    return this.createPath(this.segments.slice(0, -1));
  },
  get rootPath() {
    return this.createPath(this.segments, true);
  },
  get objectIndex() {
    return get(last(this.segments), 'index', null);
  },
  get objectType() {
    return get(last(this.segments), 'segment', last(this.segments));
  }
});
