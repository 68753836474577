import { createAsyncThunk } from '@reduxjs/toolkit';
import { isString } from 'lodash-es';

import { request } from '@shared/api';
import {
  PaginatedData,
  Truck as TruckInterface,
  LeaseTruck as LeaseTruckInterface
} from '@shared/types';
import { createPageParams } from '@shared/utils';
import { TruckFormValues, LeaseTruckFormValues } from './types';
import { normalizeTruckForPost, normalizeLeaseTruckForPost } from './utils';

const prepareImageUploadRequest = <T>(
  truckForm: TruckFormValues | LeaseTruckFormValues,
  key: string,
  truckId?: string
) => {
  if (!truckForm.photo || isString(truckForm.photo)) return Promise.resolve(false);

  const formData = new FormData();

  formData.append(`${key}[id]`, truckId || truckForm.id.toString());
  formData.append(`${key}[photo]`, truckForm.photo);

  return request<T, FormData>('put', `${key}s/${truckId || truckForm.id}`, formData);
};

export const getTrucks = createAsyncThunk('trucks/getTrucks', async () => {
  const responses = await Promise.all([
    request<PaginatedData<TruckInterface>>(
      'get',
      `trucks?${createPageParams({ number: 1, size: 100000 })}`
    ),
    request<PaginatedData<LeaseTruckInterface>>(
      'get',
      `lease_trucks?${createPageParams({ number: 1, size: 100000 })}`
    )
  ]);

  return responses;
});

export const createTruck = createAsyncThunk(
  'trucks/createTruck',
  async (truckForm: TruckFormValues) => {
    const truck = normalizeTruckForPost(truckForm);
    const newTruck = await request<TruckInterface, { truck: TruckInterface }>('post', 'trucks', {
      truck
    });
    const finalTruck = await prepareImageUploadRequest(truckForm, 'truck', newTruck.id.toString());

    if (!finalTruck) return newTruck;
    return finalTruck as TruckInterface;
  }
);

export const updateTruck = createAsyncThunk(
  'trucks/updateTruck',
  async (truckForm: TruckFormValues) => {
    const updatedTruck = await request<TruckInterface, { truck: TruckInterface }>('patch', `trucks/${truckForm.id}`, {
      truck: normalizeTruckForPost(truckForm)
    });
    const finalTruck = await prepareImageUploadRequest(truckForm, 'truck');

    if (!finalTruck) return updatedTruck;
    return finalTruck as TruckInterface;
  }
);

export const createLeaseTruck = createAsyncThunk(
  'trucks/createLeaseTruck',
  async (leaseTruckForm: LeaseTruckFormValues) => {
    const leaseTruck = normalizeLeaseTruckForPost(leaseTruckForm);
    const newLeaseTruck = await request<LeaseTruckInterface, { leaseTruck: LeaseTruckInterface }>(
      'post',
      'lease_trucks',
      {
        leaseTruck
      }
    );

    const finalLeaseTruck = await prepareImageUploadRequest(
      leaseTruckForm,
      'lease_truck',
      newLeaseTruck.id.toString()
    );

    if (!finalLeaseTruck) return newLeaseTruck;
    return finalLeaseTruck as LeaseTruckInterface;
  }
);

export const updateLeaseTruck = createAsyncThunk(
  'trucks/updateLeaseTruck',
  async (leaseTruckForm: LeaseTruckFormValues) => {
    const updatedLeaseTruck = await request<LeaseTruckInterface, { leaseTruck: LeaseTruckInterface }>(
      'patch',
      `lease_trucks/${leaseTruckForm.id}`,
      {
        leaseTruck: normalizeLeaseTruckForPost(leaseTruckForm)
      }
    );
    const finalLeaseTruck = await prepareImageUploadRequest(leaseTruckForm, 'lease_truck');

    if (!finalLeaseTruck) return updatedLeaseTruck;
    return finalLeaseTruck as LeaseTruckInterface;
  }
);


export const deleteTruck = createAsyncThunk(
  'trucks/deleteTruck',
  async (truckId: number) => {
    return await request<boolean>('delete', `trucks/${truckId}`);
  }
);


export const deleteLeaseTruck = createAsyncThunk(
  'trucks/deleteLeaseTruck',
  async (truckId: number) => {
    return await request<boolean>('delete', `lease_trucks/${truckId}`);
  }
);
