import { SelectField, SelectFieldProps } from '@components/form-fields';
import { SelectOption } from '@shared/types';
import { formatAsYesNo } from '@shared/utils';
import React from 'react';
import { Field } from 'react-final-form';

const yesNoOptions: SelectOption<string>[] = ['Yes', 'No'].map(value => ({ label: value, value }));

const parseYesNoAsBoolean = (value: 'Yes' | 'No') => value?.toLowerCase() === 'yes';

interface BooleanSelectFieldProps
  extends Pick<SelectFieldProps, 'size' | 'label' | 'isDisabled' | 'autoWidth'> {
  name: string;
  className?: string;
}

export const BooleanSelectField = ({
  name,
  ...selectFieldProps
}: BooleanSelectFieldProps): JSX.Element => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Field<any>
      name={name}
      parse={parseYesNoAsBoolean}
      format={formatAsYesNo}
      render={props => <SelectField {...props} {...selectFieldProps} options={yesNoOptions} />}
    />
  );
};
