import { ArrayIterator } from 'lodash';

export const partitionBy = <T>(
  collection: T[],
  callback: ArrayIterator<T, boolean>
): [T[], T[]] => {
  return collection.reduce<[T[], T[]]>(
    ([group1, group2], value, index) => {
      return callback(value, index, collection)
        ? [group1.concat(value), group2]
        : [group1, group2.concat(value)];
    },
    [[], []]
  );
};
