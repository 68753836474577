import { createAsyncThunk } from "@reduxjs/toolkit";
import { BillingGroup } from "@shared/types";
import { request } from "@shared/api";

export const getBillingGroups = createAsyncThunk('billingGroups/getBillingGroups', async () => {
    const response = await request<BillingGroup[]>(
        'get',
        `billing_groups`
    );

    return response
});
