import { AlertsState, setGlobalErrors, RootState, useAppDispatch } from '@shared/store';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';

interface GlobalAlertsProps {
  className?: string;
  dismissInSeconds?: number;
}

export const GlobalAlerts = ({
  className,
  dismissInSeconds
}: GlobalAlertsProps): JSX.Element | null => {
  const { errors } = useSelector<RootState, AlertsState>(state => state.alerts);
  const dispatch = useAppDispatch();

  const dismissErrors = useCallback(() => dispatch(setGlobalErrors([])), [dispatch]);

  useEffect(() => {
    if (errors.length > 0 && dismissInSeconds) {
      const timeoutId = setTimeout(dismissErrors, dismissInSeconds * 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [dismissErrors, dismissInSeconds, errors.length]);

  return errors.length === 0 ? null : (
    <Alert className={classNames(className)} variant="danger" dismissible onClose={dismissErrors}>
      <ul className="list-unstyled mb-0">
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </Alert>
  );
};
