import { has, isObject } from 'lodash-es';
import { Employee } from './Employee';
import { RailsObject } from './RailsObject';
import { Truck } from './Truck';

export interface ResourceAssignment extends RailsObject {
  crewId: number;
  employeeId: number;
  truckId?: number | null;
  truckNote?: string | null;
  foreman: boolean;
  crewmemberNote?: string | null;
  ordinal?: number | null;
  type?: string;
  updateEmployee?: boolean;
  updateTruck?: boolean;
  previousTruckId?: number | null;
}

export interface DetailedResourceAssignment extends ResourceAssignment {
  employee: Employee;
  truck?: Truck;
}

export interface IndexedResourceAssignment extends DetailedResourceAssignment {
  $$index: number;
}

export const isResourceAssignment = (obj: unknown): obj is ResourceAssignment => {
  return isObject(obj) && (has(obj, 'crewId') || has(obj, 'foreman')) && has(obj, 'employeeId');
};
