export const getDivisionColor = (divisionName: string | undefined): string => {
    if (divisionName === 'paving') {
        return 'blue';
    } else if (divisionName === 'concrete') {
        return 'orange';
    } else if (divisionName === 'striping') {
        return 'green-dark'
    } else {
        return 'gray'
    }
}
