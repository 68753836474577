import { useEffect } from 'react';

export const useCustomEvent = <T = unknown>(name: string, callback: (event: CustomEvent<T>) => void): void => {
  useEffect(() => {
    window.addEventListener(name, callback);
    return () => {
      window.removeEventListener(name, callback);
    };
  }, [callback, name]);
};
