import { matchSorter, KeyOption, rankings } from 'match-sorter';
import { isEmpty } from 'lodash-es';

import { FilterValueInterface } from '@components/common';

export type SearchableItemInterface = Record<string, unknown>;

interface SearchItemsOptions<T> {
  filterValues: FilterValueInterface;
  filterKeys: Record<string, string | string[]>;
  searchKeys?: KeyOption<T>[];
  searchValue?: string;
}

export function searchItems<T = SearchableItemInterface>(
  items: T[] = [],
  { searchValue, searchKeys, filterValues, filterKeys }: SearchItemsOptions<T>
): T[] {
  if (!searchValue?.length && isEmpty(filterValues)) return items;

  let preparedList: T[] = items;

  if (!isEmpty(filterValues)) {
    for (const key in filterValues) {
      if (filterValues[key] === '') continue;
      const isFuzzySearch = Array.isArray(filterKeys[key]);
      const keys = isFuzzySearch ? (filterKeys[key] as string[]) : [key];

      preparedList = matchSorter(preparedList, filterValues[key], {
        keys: keys.map(k => ({
          key: k,
          threshold: isFuzzySearch ? rankings.CONTAINS : rankings.EQUAL
        }))
      });
    }
  }

  if (searchValue && !isEmpty(searchKeys)) {
    preparedList = matchSorter(items, searchValue, {
      keys: searchKeys,
      threshold: rankings.CONTAINS,
      baseSort: (a,b) => a.index - b.index
    });
  }

  return preparedList;
}
