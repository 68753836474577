export const parseAndValidateNumber = (
  value?: string | number,
  { isInteger, min, max }: { isInteger?: boolean; min?: number; max?: number } = {}
): number => {
  const numberValue = Number(value);
  if (Number.isNaN(numberValue)) throw new Error('Must be a number');
  if (isInteger && !Number.isInteger(numberValue)) throw new Error('Must be an integer');

  if (min && numberValue < min) throw new Error(`Must be ${min} at minimum`);
  if (max && numberValue > max) throw new Error(`Must be ${max} at maximum`);

  return numberValue;
};
