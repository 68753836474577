import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import classnames from 'classnames';
import { random } from 'lodash-es';

import { ClickableAction as ClickableActionInterface } from '@shared/types';
import { Avatar, AvatarProps } from '../avatar';
import { Icon } from '../icon';
import { IconWithText } from '../icon-with-text';
import './styles/info-bug.scss';

export interface InfoBugProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  /** A label to render inside the `InfoBug`. */
  label: string;
  /** An optional secondary label to render below the primary label. */
  subLabel?: string;
  /** Attaches an [`Avatar`](/?path=/docs/common-components-avatar). */
  avatar?: AvatarProps;
  /** Sets the border color. */
  color?: string;
  /** One or more actions to populate a dropdown menu. */
  actions?: ClickableActionInterface[];
  /** Marks the `InfoBug` with special styling. */
  isAccented?: boolean;
  /** Removes all borders except the left one.. */
  isNested?: boolean;
  /** Reduces inner padding by half to decrease height. */
  isTight?: boolean;
  /** Visually marks the `InfoBug` with a "deleted" state. */
  isMarked?: boolean;
  /** Visually marks the `InfoBug` with a "danger" state. */
  isDanger?: boolean;
  /** Handles a situation where an `InfoBug` needs to sit inside a cell
   *  with the `dotted-line-bg` class. This ensures the line is carried inside
   *  without obscuring the label.
   */
  hasDottedLineBg?: boolean;
}

export const InfoBug: React.FC<InfoBugProps> = ({
  label,
  avatar,
  subLabel,
  isAccented = false,
  isNested = false,
  isTight = false,
  isMarked = false,
  isDanger = false,
  hasDottedLineBg = false,
  color = '',
  actions = [],
  className = '',
  ...props
}) => {
  return (
    <div
      className={classnames(
        'info-bug',
        {
          [`info-bug--${color}`]: color.length > 0,
          'info-bug--accented': isAccented,
          'info-bug--nested': isNested,
          'info-bug--tight': isTight,
          'info-bug--marked': isMarked,
          'info-bug--dotted': hasDottedLineBg,
          'info-bug--danger': isDanger,
          'pr-0': actions.length > 0
        },
        className
      )}
      {...props}
    >
      <div className="info-bug__main">
        {avatar && (
          <span className="info-bug-avatar">
            <Avatar {...avatar} />
          </span>
        )}
        <span className="info-bug-label">{label}</span>
        {hasDottedLineBg && <span className="info-bug-divider" />}
        {actions && actions.length > 0 && (
          <div className="info-bug-actions">
            <DropdownButton
              variant="link"
              size="sm"
              menuAlign="right"
              id={`DropdownButton_${random(1, 50000)}`}
              title={<Icon name="menu-dots" />}
            >
              {actions.map(({ label, icon, onClick }, index) => (
                <Dropdown.Item key={`DropdownItem_${index}`} as="button" onClick={onClick}>
                  {icon && (
                    <IconWithText iconName={icon} alignment="right" iconPosition="right">
                      {label}
                    </IconWithText>
                  )}
                  {!icon && label}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        )}
      </div>
      {subLabel && <span className="info-bug__sublabel">{subLabel}</span>}
    </div>
  );
};
