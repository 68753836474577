import React from 'react';
import classnames from 'classnames';
import { Card, CardProps } from 'react-bootstrap';

import './styles/data-card.scss';

export interface DataCardProps extends CardProps {
  header?: string;
  canOverflow?: boolean;
  cardBodyClassNames?: string;
}

export const DataCard: React.FC<DataCardProps> = ({
  children,
  header,
  canOverflow = false,
  className,
  cardBodyClassNames,
  ...props
}) => (
  <Card className={classnames('data-card', 'bg-gray-100', className)} {...props}>
    {header && <Card.Header className="bg-black text-white text-center py-1">{header}</Card.Header>}
    <Card.Body
      className={classnames(
        'py-2',
        'px-3',
        {
          'overflow-auto': canOverflow
        },
        cardBodyClassNames
      )}
    >
      {children}
    </Card.Body>
  </Card>
);
