import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { Employee as EmployeeInterface } from '@shared/types';
import { RootState } from '@shared/store';
import { EmployeesState } from './types';
import { getEmployees, createEmployee, updateEmployee, deleteEmployee } from './thunks';

const initialState: EmployeesState = {
  allEmployees: [],
  isLoaded: false,
  isLoading: false,
  isSubmitting: false,
  activeEmployee: null,
  errorMessages: []
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    startSubmitEmployees(state) {
      state.isSubmitting = true;
    },
    endSubmitEmployees(state) {
      state.isSubmitting = false;
    },
    startLoadEmployees(state) {
      state.isLoading = true;
    },
    endLoadEmployees(state) {
      state.isLoading = false;
    },
    setEmployeesError(state, action: PayloadAction<string[]>) {
      state.errorMessages = action.payload;
    },
    setActiveEmployee(state, action: PayloadAction<EmployeeInterface | null>) {
      state.activeEmployee = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getEmployees.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(getEmployees.fulfilled, (state, action) => {
      const { data, page } = action.payload;

      state.allEmployees = data;
      state.page = page;
      state.isLoading = false;
      state.isLoaded = true;
    });

    builder.addCase(createEmployee.fulfilled, (state, action) => {
      state.allEmployees = state.allEmployees.concat([action.payload]);
      state.activeEmployee = action.payload;
      state.isSubmitting = false;
    });

    builder.addCase(createEmployee.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }

      state.isSubmitting = false;
    });

    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      const employeeIndex = state.allEmployees.findIndex(
        employee => employee.id === action.payload.id
      );

      if (employeeIndex !== -1) {
        state.allEmployees[employeeIndex] = action.payload;
      }

      state.activeEmployee = action.payload;
      state.isSubmitting = false;
    });

    builder.addCase(deleteEmployee.pending, state => {
      state.isSubmitting = true;
    });

    builder.addCase(deleteEmployee.fulfilled, (state, { payload: didDelete }) => {
      const index = state.allEmployees.findIndex(
        employee => employee.id === state.activeEmployee?.id
      );

      if (index >= 0) {
        state.allEmployees[index].isDiscarded = didDelete;
      }

      state.activeEmployee = null;
      state.isSubmitting = false;
    });

    builder.addCase(deleteEmployee.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }

      state.isSubmitting = false;
    });
  }
});

export const {
  startSubmitEmployees,
  endSubmitEmployees,
  startLoadEmployees,
  endLoadEmployees,
  setEmployeesError,
  setActiveEmployee
} = employeesSlice.actions;
export default employeesSlice.reducer;

export const useEmployeesSelector = (): EmployeesState =>
  useSelector<RootState, EmployeesState>(state => state.employees);
