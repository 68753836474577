import { useRef, useEffect } from 'react';

export const usePrevious = <T extends unknown>(value: T): T => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current as T;
};
