import React, { useMemo } from 'react';
import classnames from 'classnames';
import { isFunction, isUndefined } from 'lodash-es';
import { ListGroup } from 'react-bootstrap';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { ListItem } from './ListItem';
import { ListProps } from '../List';
import { ListItem as ListItemInterface } from '../types/ListItem';
import { ListItemVirtualRenderer } from './ListItemVirtualRenderer';

interface ListItemListProps extends Pick<ListProps, 'nested' | 'noRecordMessage'> {
  items: ListItemInterface[];
  handleLinkAction: (onClick: () => void, event: Event) => void;
  useVirtualList?: boolean;
  hasNoRecords: boolean;
  hasNoMatches: boolean;
}

interface AnnotatedListItem extends ListItemInterface {
  hasAction: boolean;
}

export const ListItemList = ({
  nested,
  handleLinkAction,
  noRecordMessage,
  useVirtualList,
  items,
  hasNoMatches,
  hasNoRecords
}: ListItemListProps): JSX.Element => {
  const formattedItems = useMemo(() => {
    return items.map<AnnotatedListItem>(item => {
      const { link, linkAction } = item;
      const hasAction = [link, linkAction].some(a => !isUndefined(a));

      return {
        ...item,
        actions: hasAction ? undefined : item.actions,
        hasAction
      };
    });
  }, [items]);


  return (
    <ListGroup variant={nested ? 'flush' : undefined} className="h-100">
      {hasNoRecords && <ListGroup.Item>{noRecordMessage}</ListGroup.Item>}
      {hasNoMatches && <ListGroup.Item>No matches found.</ListGroup.Item>}
      {!useVirtualList &&
        formattedItems.length > 0 &&
        formattedItems.map(({ link, linkAction, hasAction, ...item }, index) => (
          <ListGroup.Item
            key={`listItem_${index}`}
            action={hasAction}
            className={classnames(
              {
                'is-active': item.isActive,
                'p-0': isFunction(item.render)
              },
              item.wrapperClassName
            )}
            {...{ href: link, onClick: handleLinkAction.bind(this, linkAction || null) }}
          >
            <ListItem {...item} />
          </ListGroup.Item>
        ))}
      {useVirtualList && (
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              itemSize={80}
              width={width}
              height={height}
              itemCount={formattedItems.length}
              itemData={{ handleLinkAction, items: formattedItems }}
            >
              {ListItemVirtualRenderer}
            </FixedSizeList>
          )}
        </AutoSizer>
      )}
    </ListGroup>
  );
};
