import React from 'react';
import classnames from 'classnames';
import { isFunction, isUndefined } from 'lodash-es';
import { ListGroup } from 'react-bootstrap';
import { ListChildComponentProps } from 'react-window';
import { ListItem as ListItemInterface } from '../types/ListItem';
import { ListItem } from '../partials';

interface AnnotatedListItem extends ListItemInterface {
  hasAction: boolean;
}

interface ListChildComponentData {
  handleLinkAction: (onClick: () => void, event: Event) => void;
  items: AnnotatedListItem[];
}

type ListItemVirtualRendererProps = ListChildComponentProps<ListChildComponentData>;

export const ListItemVirtualRenderer = ({
  index,
  style,
  data
}: ListItemVirtualRendererProps): JSX.Element => {
  const { link, linkAction, ...item } = data.items[index];
  const hasAction = [link, linkAction].some(a => !isUndefined(a));

  return (
    <ListGroup.Item
      key={`listItem_${index}`}
      style={style}
      action={hasAction}
      className={classnames(
        'd-flex',
        {
          'is-active': item.isActive,
          'p-0': isFunction(item.render)
        },
        item.wrapperClassName
      )}
      {...{ href: link, onClick: data.handleLinkAction.bind(this, linkAction || null) }}
    >
      <ListItem {...item} wrapperClassName="list-item--virtual" actions={hasAction ? undefined : item.actions} />
    </ListGroup.Item>
  );
};
