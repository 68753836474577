import { kebabCase } from 'lodash-es';
 import { ServiceType, serviceType } from '@shared/constants';
import { ServiceDetail } from '@shared/types';
import serviceTypes from 'public/serviceTypesV5.json';

const SERVICE_TYPE_COLOR_MAP = serviceTypes.reduce(
  (mapping: Record<keyof typeof serviceType, string>, { slug, color }) => ({
    ...mapping,
    [slug]: kebabCase(color)
  }),
  {}
);

export const newServiceDetail = (jobId: number, serviceTypeName?: ServiceType): ServiceDetail => ({
  jobId,
  serviceType: serviceTypeName,
  id: undefined,
  flaggersNeeded: false,
  flaggerQuantity: 0,
  locateNeeded: false,
  striping: undefined,
  notes: ' ',
  photos: [],
  photoPaths: {}
});

export const getServiceDetailColor = (serviceDetailType: string | number | undefined | ''): string => {
  if (!serviceDetailType) return 'black';
  return SERVICE_TYPE_COLOR_MAP[serviceDetailType];
};
