import React, { useCallback } from 'react';

import { IconWithText, Icon } from '@components/common';
import { FilterControl } from '@components/common/filter-control';
import { ListSearch } from './ListSearch';
import { ListProps } from '../List';
import '../styles/list-panel.scss';

export interface ListPanelProps
  extends Pick<ListProps, 'searchPlaceholder' | 'searchKeys' | 'filterControls'> {
  setShowFilterPanel: React.Dispatch<React.SetStateAction<boolean>>;
  searchValue: string;
  setSearchValue: (value: string) => void;
  activeFilterValues: Record<string, string>;
  setActiveFilterValues: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

export const ListPanel = ({
  setShowFilterPanel,
  activeFilterValues,
  setActiveFilterValues,
  searchPlaceholder = '',
  filterControls = [],
  setSearchValue,
  searchValue = ''
}: ListPanelProps): JSX.Element => {
  const handleFilterControlChange = useCallback(
    (value: string, slug: string) => {
      setActiveFilterValues({
        ...activeFilterValues,
        [slug]: value
      });
    },
    [activeFilterValues, setActiveFilterValues]
  );

  const handleClearFilterControl: React.MouseEventHandler = useCallback(
    event => {
      event.preventDefault();

      setActiveFilterValues({});
      setSearchValue('');
    },
    [setActiveFilterValues, setSearchValue]
  );

  const handleSetFilterPanel = useCallback(
    (isVisible: boolean, event: MouseEvent) => {
      event.preventDefault();
      setShowFilterPanel(isVisible);
    },
    [setShowFilterPanel]
  );

  return (
    <div className="list-panel p-3">
      <button
        className="text-dark border-0 bg-transparent d-inline-flex align-items-center mb-2 p-0"
        onClick={handleSetFilterPanel.bind(this, false)}
      >
        <Icon name="arrow-left" />
        <span className="ml-2">Back</span>
      </button>
      <IconWithText iconName="settings" className="mb-1 h4 font-weight-bold">
        Filters
      </IconWithText>
      <button
        className="border-0 bg-transparent text-primary p-0 mb-3"
        onClick={handleClearFilterControl}
      >
        Clear Filters
      </button>
      <div className="mb-3">
        <ListSearch
          value={searchValue}
          setSearchValue={setSearchValue}
          placeholder={searchPlaceholder}
        />
      </div>
      {filterControls.length > 0 &&
        filterControls.map((control, index) => (
          <div key={`FilterControl_${index}`} className="mb-3">
            <FilterControl
              {...control}
              value={activeFilterValues[control.slug]}
              onChange={handleFilterControlChange}
            />
          </div>
        ))}
    </div>
  );
};
