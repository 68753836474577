import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Form } from 'react-bootstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { preventSubmitOnEnter } from '@utils';
import { FieldState } from 'final-form';
import classNames from 'classnames';

dayjs.extend(customParseFormat);


const formatTimeInput = (value: string, previousValue: string): string => {

  const valueToMatch = value.trim().replace(/:/g, '');

  if (!valueToMatch) return ""

  const match = valueToMatch.match(/^((\d)|(\d{2})|(\d{3})|(\d{4}))\s?((a|p)m?)?$/i);

  if (match?.[2] || match?.[3]) {
    // we get here if the user types 9, 9am, 12pm, etc
    let time = match?.[2] || match?.[3];
    if (match[6] && time.match(/^([1-9]|0[1-9]|1[0-2])$/)) {
      time = `${time} ${match[6].toUpperCase()}`;
    }
    return time;
  }

  if (match?.[4] || match?.[5]) {
    // we get here if the user types 930, 930am, 1230pm, etc
    let time = match[4] ? `${match[4].slice(0, 1)}:${match[4].slice(1, 3)}` : `${match[5].slice(0, 2)}:${match[5].slice(2, 4)}`;
    if (match[6] && time.match(/^([1-9]|0[1-9]|1[0-2]):([0-5][0-9])$/)) {
      time = `${time} ${match[6].toUpperCase()}`;
    }
    return time;
  }

  // return the previous value if there is no match
  return previousValue;

}

interface TimeInputFieldProps extends FieldRenderProps<string, HTMLInputElement> {
  isDisabled?: boolean;
  supports24Hour?: boolean;
  label?: string;
}

export const TimeInputField = ({
  input,
  meta,
  label,
  className,
  isDisabled,
  supports24Hour,
  ...inputProps
}: TimeInputFieldProps) => {

  const [value, setValue] = useState(() => {
    return dayjs(input.value).isValid() ? dayjs(input.value).format('h:mm A') : '';
  });

  return (
    <Form.Group controlId={input.name} className={classNames("", className)}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        {...inputProps}
        value={value}
        onChange={e => setValue(formatTimeInput(e.target.value, value))}
        onBlur={() => {
          let finalValue = value;
          const match = finalValue.match(/^([1-9]|0[1-9]|1[0-2])(:[0-5][0-9])?(\s(a|p)m?)?$/i);
          if (match) {
            // we get here if the user typed a valid time but may be missing AM/PM
            if (!match?.[3]) {
              finalValue = finalValue + ' AM'
            }
            else if (match?.[3].length === 2) {
              finalValue = finalValue + 'M'
            }
          }

          // 9 AM, 12 PM, 9:00 AM, 09:00 AM are all valid time formats.
          // If supports24Hour is set to true, then 14, 14:00, etc are also valid
          const date = dayjs(finalValue, ['h A', 'hh A', 'h:mm A', 'hh:mm A', ...(supports24Hour ? ['H', 'HH', 'H:mm', 'HH:mm'] : [])], true);
          if (date.isValid()) {
            input.onChange(date.toISOString());
            setValue(date.format('h:mm A'));
          } else {
            // call input.onChange() with the invalid value to trigger validation
            input.onChange(finalValue);
            setValue(finalValue);
          }
        }}
        onKeyDown={preventSubmitOnEnter}
        style={{ width: 120 }}
        placeholder="hhmm am/pm"
        disabled={isDisabled}
        isInvalid={meta.invalid && (meta.modified || meta.touched)}
      />
      {(meta.error) && (
        <Form.Control.Feedback type="invalid">
          {meta.error}
        </Form.Control.Feedback>
      )}
    </Form.Group >
  );
};

export const validateISOTime = (value: string, allValues: object, meta?: FieldState<string>) => {
  if (value && !dayjs(value, ["YYYY-MM-DDTHH:mm:ss[Z]", "YYYY-MM-DDTHH:mm:ss.SSS[Z]"], true).isValid()) {
    return 'Invalid time format';
  }
  return undefined;
}
