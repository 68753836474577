import { ClickableAction } from '@shared/types';

export const caDelete = (callback: () => void): ClickableAction => ({
  label: 'Delete',
  icon: 'trash',
  onClick: event => {
    event.preventDefault();
    callback();
  }
});

export const caRestore = (callback: () => void): ClickableAction => ({
  label: 'Restore',
  icon: 'plus-circle',
  onClick: event => {
    event.preventDefault();
    callback();
  }
});

export const caReplace = (callback: () => void): ClickableAction => ({
  label: 'Replace',
  icon: 'refresh',
  onClick: event => {
    event.preventDefault();
    callback();
  }
});
