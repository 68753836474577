import React, { useState, useCallback, useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import { Icon } from '@components/common';
import { FilterControlInterface } from './types';
import './styles/filter-control.scss';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@components/form-fields';

export interface FilterControlProps extends FilterControlInterface {
  value: string;
  requireManualUpdate?: boolean;
  onChange: (value: string, slug: string) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const ensureValidDate = (date: Dayjs, fallbackValue?: Dayjs) => {
  return date.isValid() ? date : fallbackValue;
};

export const FilterControl = ({
  slug,
  label,
  type,
  onChange,
  onKeyUp,
  placeholder,
  requireManualUpdate = true,
  value = '',
  options = []
}: FilterControlProps): JSX.Element => {
  const [filterValue, setFilterValue] = useState(value);

  const handleTextFieldChange = useCallback(
    event => {
      setFilterValue(event.target.value);
      if (requireManualUpdate) {
        onChange(event.target.value, slug);
      }
    },
    [requireManualUpdate, onChange, slug]
  );

  const handleTextFieldKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (requireManualUpdate) {
        onKeyUp?.(event);
      } else {
        if (event.key === 'Enter') {
          onChange(filterValue, slug);
        }
      }
    },
    [onChange, filterValue, slug, onKeyUp, requireManualUpdate]
  );

  const handleTextFieldBlur = useCallback(() => {
    if (!requireManualUpdate && filterValue !== value) {
      onChange(filterValue, slug);
    }
  }, [requireManualUpdate, onChange, filterValue, slug, value]);

  useEffect(() => {
    setFilterValue(value);
  }, [value]);

  return (
    <Form.Group controlId={`filterControl_${slug}`} className="filter-control">
      <Form.Label>{label}</Form.Label>
      {type === 'date' && (
        <div>
          <DatePicker
            placeholderText="MM/DD/YYYY"
            selected={ensureValidDate(dayjs(value))?.toDate()}
            onChange={date => {
              onChange(ensureValidDate(dayjs(date as Date))?.format('YYYY-MM-DD') ?? '', slug);
            }}
          />
        </div>
      )}
      {type === 'text' && (
        <InputGroup>
          <Form.Control
            {...{
              type,
              value: filterValue,
              placeholder
            }}
            onKeyUp={handleTextFieldKeyUp}
            onChange={handleTextFieldChange}
            onBlur={handleTextFieldBlur}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <Icon name="search" />
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      )}
      {type === 'select' && (
        <Form.Control
          as="select"
          value={value}
          onChange={({ target }) => onChange(target.value, slug)}
        >
          <option value="">All</option>
          {options.map((option, optionIndex) => (
            <option key={`option_${optionIndex}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Control>
      )}
    </Form.Group>
  );
};
