
const isSafari = () => {
    return navigator.userAgent.toLowerCase().includes('safari')
}

/**
 * Prints an external URL using a hidden iframe without opening it. 
 * 
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_media_queries/Printing for more information.
 * 
 * @param url 
 */
export function printDocument({
    url,
    onPrintDialogOpen,
    onPrintDialogClose
}: {
    url: string;
    onPrintDialogOpen?: () => void;
    onPrintDialogClose?: () => void;
}): void {

    let printDialogOpen = false
    const hiddenFrame = document.createElement("iframe");
    hiddenFrame.onload = function (this: HTMLIFrameElement) {
        const closePrint = () => {
            document.body.removeChild(this);
        };
        if (!this.contentWindow) return;

        this.contentWindow.onbeforeunload = closePrint
        this.contentWindow.onafterprint = closePrint

        if(isSafari()){
            setTimeout(() => {
                this.contentWindow?.print();
                onPrintDialogOpen?.()
                printDialogOpen = true
            }, 1000)
        }
        else {
            this.contentWindow?.print();
            onPrintDialogOpen?.()
            printDialogOpen = true
        }

    }
    hiddenFrame.style.display = "none"; // hide iframe
    hiddenFrame.src = url
    document.body.appendChild(hiddenFrame);
    window.addEventListener('mouseover', function () {
        if (printDialogOpen) {
            document.body.removeChild(hiddenFrame);
            onPrintDialogClose?.()
        }
    });
}
