import React from 'react';
import classnames from 'classnames';

import './styles/drop-zone.scss';

interface DropZoneProps {
  isVisible: boolean;
}

export const DropZone: React.FC<DropZoneProps> = ({ isVisible }) => {
  return (
    <div
      className={classnames('drop-zone', {
        'drop-zone--active': isVisible
      })}
    ></div>
  );
};
