import { combineReducers } from '@reduxjs/toolkit';
import {
  billingGroupsReducer,
  divisionsReducer,
  currentUserReducer,
  employeesReducer,
  scheduleReducer,
  jobsReducer,
  trucksReducer,
  truckReportsReducer,
  usersReducer,
  alertsReducer,
  materialsReducer
} from './reducers';

const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  schedule: scheduleReducer,
  billingGroups: billingGroupsReducer,
  divisions: divisionsReducer,
  employees: employeesReducer,
  trucks: trucksReducer,
  truckReports: truckReportsReducer,
  users: usersReducer,
  jobs: jobsReducer,
  alerts: alertsReducer,
  materials: materialsReducer
});

export default rootReducer;
