import { Icon } from '@components/common';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { Col, Form, FormControlProps, Row } from 'react-bootstrap';
import { FieldRenderProps } from 'react-final-form';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import classNames from 'classnames';

interface DateInputProps extends Pick<FormControlProps, 'size'> {
  value?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement | SVGSVGElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  placeholder?: string;
}

const controlSizeToIconSize = { sm: 'small', md: 'medium', lg: 'large' } as const;

export const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  ({ size, value, onChange, onClick, disabled, placeholder }, ref) => (
    <div className="position-relative">
      <Form.Control
        ref={ref}
        size={size}
        value={value}
        onChange={onChange}
        onClick={onClick}
        disabled={disabled}
        placeholder={placeholder}
        className={classNames({ 'bg-transparent': !disabled })}
      />
      <Icon
        name="calendar"
        size={controlSizeToIconSize[size ?? 'md']}
        className={classNames('position-absolute mr-2 mt-2')}
        style={{ top: '0px', right: '0px', zIndex: 10, cursor: 'pointer' }}
        onClick={onClick}
      />
    </div>
  )
);

interface DatePickerProps extends ReactDatePickerProps {
  size?: 'sm' | 'lg';
}

export const DatePicker = ({ size, ...props }: DatePickerProps): JSX.Element => {
  return <ReactDatePicker {...props} customInput={<DateInput size={size} />} />;
};

export interface DatePickerFieldProps
  extends Omit<DatePickerProps, 'selected' | 'onChange'>,
    FieldRenderProps<Date | undefined, HTMLElement> {
  label?: string;
  inline?: boolean;
  labelColSize?: number | 'auto';
}

const ensureValidDate = (date: Dayjs, fallbackValue?: Dayjs) => {
  return date.isValid() ? date : fallbackValue;
};

export const DatePickerField = ({
  input,
  meta,
  label,
  inline,
  labelColSize,
  size,
  className,
  placeholderText = 'MM/DD/YYYY',
  ...datePickerProps
}: DatePickerFieldProps): JSX.Element => {
  const datePicker = (
    <div className="d-block">
      <DatePicker
        {...datePickerProps}
        placeholderText={placeholderText}
        size={size}
        selected={ensureValidDate(dayjs(input.value))?.toDate()}
        onChange={date => {
          input.onChange(ensureValidDate(dayjs(date as Date))?.format('YYYY-MM-DD'));
        }}
      />
      {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
    </div>
  );
  return inline ? (
    <Form.Group controlId={input.name} column={size} as={Row} className={className}>
      <Form.Label column={size} sm={labelColSize}>
        {label}
      </Form.Label>
      <Col>{datePicker}</Col>
    </Form.Group>
  ) : (
    <Form.Group controlId={input.name} className={className}>
      {label && <Form.Label>{label}</Form.Label>}
      {datePicker}
    </Form.Group>
  );
};
