import { createAsyncThunk } from '@reduxjs/toolkit';
import { isString } from 'lodash';

import { request } from '@shared/api';
import { PaginatedData, Employee as EmployeeInterface } from '@shared/types';
import { createPageParams } from '@shared/utils';
import { EmployeeFormValues } from './types';
import { normalizeEmployeeForPost } from './utils';

const prepareImageUploadRequest = <T>(employeeForm: EmployeeFormValues, employeeId?: string) => {
  if (!employeeForm.photo || isString(employeeForm.photo)) return Promise.resolve(false);

  const formData = new FormData();

  formData.append('employee[id]', employeeId || employeeForm.id.toString());
  formData.append('employee[photo]', employeeForm.photo);

  return request<T, FormData>('put', `employees/${employeeId || employeeForm.id}`, formData);
};

export const getEmployees = createAsyncThunk('employees/getEmployees', async () => {
  const response = await request<PaginatedData<EmployeeInterface>>(
    'get',
    `employees?${createPageParams({ number: 1, size: 100000 })}`
  );

  return response;
});

export const createEmployee = createAsyncThunk(
  'employees/createEmployee',
  async (employeeForm: EmployeeFormValues) => {
    const employee = normalizeEmployeeForPost(employeeForm);
    const newEmployee = await request<EmployeeInterface, { employee: EmployeeInterface }>(
      'post',
      'employees',
      {
        employee
      }
    );
    const finalEmployee = await prepareImageUploadRequest(employeeForm, newEmployee.id.toString());

    if (!finalEmployee) return newEmployee;
    return finalEmployee as EmployeeInterface;
  }
);

export const updateEmployee = createAsyncThunk(
  'employees/updateEmployee',
  async (employeeForm: EmployeeFormValues) => {
    const employee = normalizeEmployeeForPost(employeeForm);
    const updatedEmployee = await request<EmployeeInterface, { employee: EmployeeInterface }>(
      'patch',
      `employees/${employeeForm.id}`,
      {
        employee
      }
    );
    const finalEmployee = await prepareImageUploadRequest(employeeForm);

    if (!finalEmployee) return updatedEmployee;
    return finalEmployee as EmployeeInterface;
  }
);

export const deleteEmployee = createAsyncThunk(
  'employees/deleteEmployee',
  async (employeeId: number) => {
    return await request<boolean>('delete', `employees/${employeeId}`);
  }
);
