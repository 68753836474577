import { Truck } from './Truck';
import { has, isObject } from 'lodash-es';
import { RailsObject } from './RailsObject';
import { GeoState } from '@shared/constants';
import { Division } from './Division';

export interface Employee extends RailsObject {
  cellphone?: string;
  displayedName?: string | null;
  divisionId?: number | null;
  division?: Division | undefined;
  email?: string;
  employeeType: 'foreman' | 'employee';
  firstName: string;
  fullName: string;
  name: string;
  hasCdlLicense?: boolean;
  hasValidDriversLicense?: boolean;
  hourlyRate: number;
  isTriaxleTruckDriver: boolean;
  isDiscarded: boolean;
  isContractor?: boolean;
  isSalesRep?: boolean;
  languageCode: 'english' | 'spanish';
  lastName: string;
  photoPath?: string;
  photo?: string | null;
  title: string;
  state?: GeoState;
}

export interface DetailedEmployee extends Employee {
  truck?: Truck;
}

export const isEmployee = (obj: unknown): obj is Employee => {
  return isObject(obj) && has(obj, 'employeeType');
};

export const employeeLabel = (employee?: Employee): string => {
  return [
    employee?.name,
    employee?.hasCdlLicense ? '🚚' : null,
    employee?.hasValidDriversLicense ? '🪪' : null
  ].filter(Boolean).join(' ') ?? '';
}
