import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@shared/api';
import { MaterialTypeFormValues } from '@shared/store';
import { Material, MaterialType, DetailedMaterialType, PaginatedData } from '@shared/types';
import { queryStringify } from '@utils';

export const getMaterialTypes = createAsyncThunk('materials/getMaterialTypes', async () => {
  return await request<PaginatedData<MaterialType>>('get', `/material_types?${queryStringify({
    page: {
      number: 1,
      size: 100000
    }
  })}`);
});

export const getMaterials = createAsyncThunk('materials/getMaterials', async () => {
  return await request<PaginatedData<Material>>(
    'get',
    `materials?${queryStringify({
      page: {
        number: 1,
        size: 100000
      }
    })}`
  );
});

export const createMaterialType = createAsyncThunk(
  'materials/createMaterialType',
  async (materialTypeForm: MaterialTypeFormValues) => {
    return await request<DetailedMaterialType, { materialType: MaterialTypeFormValues }>(
      'post',
      `material_types`,
      {
        materialType: materialTypeForm
      }
    );
  }
);

export const updateMaterialType = createAsyncThunk(
  'materials/updateMaterialType',
  async (materialTypeForm: MaterialTypeFormValues) => {
    return await request<DetailedMaterialType, { materialType: MaterialTypeFormValues }>(
      'patch',
      `material_types/${materialTypeForm.id}`,
      { materialType: materialTypeForm }
    );
  }
);

export const deleteMaterialType = createAsyncThunk(
  'materials/deleteMaterialType',
  async (materialId: number) => {
    return await request<boolean>('delete', `material_types/${materialId}`);
  }
);
