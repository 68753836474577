import React, { useState, useCallback, useMemo, ReactNode } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import { IconWithText } from '../icon-with-text';
import { IconProps } from '@components/common';
import { isFunction } from 'lodash-es';

interface ConfirmButtonLabelProps {
  label: ReactNode;
  confirmationRequested: boolean;
  confirmationProvided: boolean;
  icon?: string;
  confirmMessage?: string;
  confirmLabel?: string;
}

export interface ConfirmButtonProps extends ButtonProps {
  /** Appends the provided icon to the button text. */
  icon?: string;
  /** Label text for the button. */
  label?: string;
  /** If `true`, toggles the button from solid to outline (or vice versa) when the confirm state is active. */
  invertOnConfirm?: boolean;
  /** Sets a message to display when prompting for confirmation. */
  confirmMessage?: string;
  /** Sets a message to display when confirmation is provided */
  confirmLabel?: string;
}

let confirmTimeout: number;

const ConfirmButtonLabel = ({
  icon,
  label,
  confirmMessage = 'Are You Sure?',
  confirmLabel = 'Please Wait',
  confirmationRequested,
  confirmationProvided
}: ConfirmButtonLabelProps) => {
  if (!icon && !confirmationRequested) return <>{label}</>;

  let buttonLabel = label;
  let buttonIcon = icon;

  if (confirmationRequested) {
    buttonIcon = 'exclamation-circle';
    buttonLabel = confirmMessage;
  } else if (confirmationProvided) {
    buttonIcon = 'refresh';
    buttonLabel = confirmLabel;
  }

  const iconProps: Omit<IconProps, 'name'> = confirmationProvided
    ? {
        animation: 'spin',
        animationSpeed: 'medium'
      }
    : {};

  if (!buttonIcon) return <>{buttonLabel}</>;

  return (
    <IconWithText iconName={buttonIcon} iconProps={iconProps}>
      {buttonLabel}
    </IconWithText>
  );
};

export const ConfirmButton = ({
  onClick,
  confirmMessage,
  confirmLabel,
  icon,
  label,
  invertOnConfirm,
  variant,
  ...buttonProps
}: ConfirmButtonProps): JSX.Element => {
  const [confirmationRequested, setConfirmationRequest] = useState(false);
  const [confirmationProvided, setConfirmationProvided] = useState(false);

  const currentVariant = useMemo(() => {
    if (!invertOnConfirm || !confirmationRequested || !variant) return variant;
    return variant.startsWith('outline-') ? variant.replace('outline-', '') : `outline-${variant}`;
  }, [variant, invertOnConfirm, confirmationRequested]);

  const handleRequestConfirmation = useCallback(
    event => {
      if (confirmationRequested) {
        clearTimeout(confirmTimeout);
        setConfirmationRequest(false);
        setConfirmationProvided(true);

        if (isFunction(onClick)) {
          window.requestAnimationFrame(() => {
            Promise.resolve(onClick(event)).then(() => {
              setConfirmationProvided(false);
            });
          });
        }
      } else {
        setConfirmationRequest(true);

        confirmTimeout = setTimeout(() => {
          setConfirmationRequest(false);
        }, 5000);
      }
    },
    [confirmationRequested, onClick]
  );

  return (
    <Button {...buttonProps} variant={currentVariant} onClick={handleRequestConfirmation}>
      <ConfirmButtonLabel
        {...{
          icon,
          label,
          confirmMessage,
          confirmLabel,
          confirmationRequested,
          confirmationProvided
        }}
      />
    </Button>
  );
};
