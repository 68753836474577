import { usePrevious } from '@shared/utils';
import classNames from 'classnames';
import { isFunction } from 'lodash-es';
import React, { useState, useEffect } from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { Form } from 'react-bootstrap';
import { FieldRenderProps } from 'react-final-form';
import './styles/textarea-field.scss';

interface LimitedTextareaFieldProps
  extends Omit<TextareaAutosize.Props, 'size'>,
    Omit<FieldRenderProps<string, HTMLInputElement>, 'as'> {
  label?: string;
  size?: 'sm' | 'lg';
  className?: string;
  suggestedMaxLength?: number;
}

export const LimitedTextareaField = ({
  label,
  size,
  className,
  onBlur,
  maxLength,
  suggestedMaxLength = maxLength,
  input: { onChange, ...input },
  meta: { invalid, error, touched },
  ...textareaAutosizeProps
}: LimitedTextareaFieldProps): JSX.Element => {
  const [fieldValue, setFieldValue] = useState(input.value);
  const previousValue = usePrevious<string>(input.value);

  useEffect(() => {
    if (input.value !== previousValue) {
      setFieldValue(input.value);
    }
  }, [input.value, previousValue, setFieldValue]);

  return (
    <Form.Group controlId={input.name} className={classNames('textarea-field', className)}>
      {(label || maxLength) && (
        <div className="d-flex justify-content-between">
          {label && <Form.Label>{label}</Form.Label>}
          {maxLength && (
            <span>
              <strong>{fieldValue.length}</strong> of <strong>{suggestedMaxLength}</strong>{' '}
              Characters
            </span>
          )}
        </div>
      )}
      <Form.Control
        {...input}
        {...textareaAutosizeProps}
        value={fieldValue}
        onChange={({ target }) => setFieldValue(target.value)}
        onBlur={event => {
          onChange(fieldValue);
          if (isFunction(onBlur)) {
            onBlur(event);
          }
        }}
        size={size}
        maxLength={maxLength}
        as={TextareaAutosize}
        isInvalid={invalid && touched}
      />
      {error && touched && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  );
};
