import {
  Load as LoadInterface,
  Truck as TruckInterface,
  LeaseTruck as LeaseTruckInterface,
  isTruck,
  isLeaseTruck
} from '@shared/types';
import { AvatarProps, AvatarSizes, InfoBugProps } from '@components/common';
import avatarTruckPlaceholder from 'assets/images/avatar-truck-placeholder.svg';

export const getTruckColorFromLoad = (load: LoadInterface): string => {
  if (load.loadableType === 'LeaseTruck') return 'orange';
  return load.libsTruckType === 'libs_triaxles' ? 'purple' : 'green';
};

export const getTruckAvatar = (truck: TruckInterface | LeaseTruckInterface): AvatarProps => {
  return {
    url: truck?.photoPath || avatarTruckPlaceholder
  };
};

interface GetTruckInfoBugProps {
  truck: TruckInterface | LeaseTruckInterface;
  avatarSize?: AvatarSizes;
  subLabel?: string;
}

export const getTruckInfoBug = ({
  truck,
  avatarSize,
  subLabel
}: GetTruckInfoBugProps): InfoBugProps => {
  let label = '';
  let color = 'gray';

  if (isTruck(truck)) {
    label = truck.displayedName;
    color = truck.truckType === 'libs_triaxles' ? 'purple' : 'green';
  }

  if (isLeaseTruck(truck)) {
    label = truck.vendorName;
    color = 'orange';
  }

  return {
    label,
    color,
    subLabel,
    avatar: {
      ...getTruckAvatar(truck),
      size: avatarSize
    }
  };
};
