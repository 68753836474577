import {
  Employee as EmployeeInterface,
  isEmployee,
  Truck as TruckInterface,
  isTruck,
  LeaseTruck as LeaseTruckInterface,
  isLeaseTruck,
  employeeLabel
} from '@shared/types';
import { ListItemInterface } from '@components/common';
import avatarUserPlaceholder from 'assets/images/avatar-user-placeholder.svg';
import avatarTruckPlaceholder from 'assets/images/avatar-truck-placeholder.svg';

type ObjectType = EmployeeInterface | TruckInterface | LeaseTruckInterface;

export const toListItem = (
  obj: ObjectType,
  additionalProps: Partial<ListItemInterface> = {}
): ListItemInterface => {
  if (isEmployee(obj)) {
    return {
      title: employeeLabel(obj),
      subtitle: obj.title,
      image: {
        url: obj.photoPath || avatarUserPlaceholder
      },
      ...additionalProps
    };
  }

  if (isTruck(obj)) {
    return {
      title: obj.unitId,
      subtitle: obj.defaultDriver ? obj.defaultDriver?.name : 'Unassigned',
      image: {
        url: obj.photoPath || avatarTruckPlaceholder
      },
      ...additionalProps
    };
  }

  if (isLeaseTruck(obj)) {
    return {
      title: obj.vendorName,
      subtitle: obj.contactName || 'No Contact Available',
      image: {
        url: obj.photoPath || avatarTruckPlaceholder
      },
      ...additionalProps
    };
  }

  return {
    title: ''
  };
};
