import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "@shared/api";
import { Division } from "@shared/types";

export const getDivisions = createAsyncThunk('divisions/getDivisions',async () => {
    const response = await request<Division[]>(
        'get',
        'divisions'
    );

    return response;
});