import React from 'react';
import classnames from 'classnames';

import './styles/overlay-message.scss';

export interface OverlayMessageProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isVisible: boolean;
  context: 'light' | 'dark';
  isStatic?: boolean;
}

export const OverlayMessage: React.FC<OverlayMessageProps> = ({
  isVisible = false,
  context = 'light',
  isStatic = false,
  children,
  className,
  ...props
}) => (
  <div
    className={classnames(
      'overlay-message',
      `overlay-message--${context}`,
      {
        'overlay-message--is-visible': isVisible,
        'overlay-message--is-static': isStatic
      },
      className
    )}
    {...props}
  >
    {children}
  </div>
);
