const SCROLL_OFFSET = 20;

function scrollDown() {
  const nextScrollTop = document.documentElement.scrollTop + SCROLL_OFFSET;
  window.scrollTo(0, nextScrollTop);
}

function scrollUp() {
  const nextScrollTop = document.documentElement.scrollTop - SCROLL_OFFSET;
  if (nextScrollTop >= 0) {
    window.scrollTo(0, nextScrollTop);
  }
}

class SmoothScrollPlugin {
  data: { isDragging: boolean; lastMouseClientY: number | undefined } = {
    isDragging: false,
    lastMouseClientY: undefined
  };

  constructor() {
    this.addEventListeners();
  }

  onDragStart = (): void => {
    this.data = {
      isDragging: true,
      lastMouseClientY: undefined
    };
    this.smoothscroll();
  };

  onDragEnd = (): void => {
    this.data = {
      isDragging: false,
      lastMouseClientY: undefined
    };
  };

  addEventListeners = (): void => {
    window.addEventListener('dragover', e => {
      this.data.lastMouseClientY = e.clientY;
    });
  };

  smoothscroll = (): void => {
    const { isDragging, lastMouseClientY } = this.data;

    if (isDragging) {
      if (lastMouseClientY !== undefined) {
        if (lastMouseClientY > window.innerHeight - 50) {
          scrollDown();
        } else if (lastMouseClientY < 50) {
          scrollUp();
        }
      }

      window.requestAnimationFrame(this.smoothscroll);
    }
  };
}

export const smoothScrollPlugin = new SmoothScrollPlugin();
