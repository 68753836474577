import { usePrevious } from '@shared/utils';
import classNames from 'classnames';
import { isFunction } from 'lodash-es';
import React, { useState, useEffect } from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { Form } from 'react-bootstrap';
import { FieldRenderProps } from 'react-final-form';
import './styles/textarea-field.scss';
interface TextareaFieldProps
  extends Omit<TextareaAutosize.Props, 'size'>,
    Omit<FieldRenderProps<string, HTMLInputElement>, 'as'> {
  label?: string;
  size?: 'sm' | 'lg';
  className?: string;
}

export const TextareaField = ({
  label,
  size,
  className,
  onBlur,
  input: { onChange, ...input },
  meta: { invalid, error, touched },
  ...textareaAutosizeProps
}: TextareaFieldProps): JSX.Element => {
  const [fieldValue, setFieldValue] = useState(input.value);
  const previousValue = usePrevious<string>(input.value);

  useEffect(() => {
    if (input.value !== previousValue) {
      setFieldValue(input.value);
    }
  }, [input.value, previousValue, setFieldValue]);

  return (
    <Form.Group controlId={input.name} className={classNames('textarea-field', className)}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        {...input}
        {...textareaAutosizeProps}
        value={fieldValue}
        onChange={({ target }) => setFieldValue(target.value)}
        onBlur={event => {
          onChange(fieldValue);
          if (isFunction(onBlur)) {
            onBlur(event);
          }
        }}
        size={size}
        as={TextareaAutosize}
        isInvalid={invalid && touched}
      />
      {error && touched && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  );
};
