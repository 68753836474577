import React from 'react';
import { Form, InputGroup, FormControlProps } from 'react-bootstrap';
import { FieldRenderProps } from 'react-final-form';
import InputMask from 'react-input-mask';

import { Icon } from '@components/common';

export type TextFieldValueType = string | number | undefined;
export interface TextFieldProps<T extends TextFieldValueType>
  extends FieldRenderProps<T, HTMLInputElement>,
    Pick<FormControlProps, 'plaintext' | 'readOnly'> {
  label?: string;
  isDisabled?: boolean;
  autoComplete?: string;
  icon?: string;
  mask?: string;
  unit?: string;
  size?: 'sm' | 'lg';
  helpText?: string;
  overrideValue?: string;
}

export const TextField = <T extends TextFieldValueType = string>({
  input: { name, onChange, onBlur, onFocus, type, value: inputValue },
  meta: { invalid, error, modified, touched },
  label,
  icon,
  helpText,
  mask,
  unit,
  size,
  isDisabled = false,
  min,
  max,
  maxLength,
  plaintext,
  readOnly,
  autoComplete,
  placeholder,
  overrideValue,
  ...otherProps
}: TextFieldProps<T>): JSX.Element => {
  const value = overrideValue ?? inputValue;
  return (
    <Form.Group controlId={name} {...otherProps}>
      {label && <Form.Label>{label}</Form.Label>}
      {(icon || unit) && (
        <InputGroup size={size}>
          {icon && (
            <InputGroup.Prepend>
              <InputGroup.Text>
                <Icon name={icon} />
              </InputGroup.Text>
            </InputGroup.Prepend>
          )}
          {mask && !isDisabled && (
            <InputMask
              {...{
                mask,
                value,
                onChange,
                onBlur,
                onFocus,
                disabled: isDisabled,
                readOnly: isDisabled
              }}
            >
              {inputProps => (
                <>
                  <Form.Control
                    {...inputProps}
                    size={size}
                    type={type}
                    {...{ min, max, maxLength, autoComplete }}
                    {...{ plaintext, readOnly, placeholder }}
                    isInvalid={invalid && (modified || touched)}
                  />
                  <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                </>
              )}
            </InputMask>
          )}
          {(!mask || (mask && isDisabled)) && (
            <>
              <Form.Control
                {...{ name, value, onChange, onBlur, type, size, autoComplete }}
                {...{ min, max, maxLength }}
                {...{ plaintext, readOnly, placeholder }}
                disabled={isDisabled}
                isInvalid={invalid && (modified || touched)}
              />
            </>
          )}
          {unit && (
            <InputGroup.Append>
              <InputGroup.Text>{unit}</InputGroup.Text>
            </InputGroup.Append>
          )}
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </InputGroup>
      )}
      {!icon && !unit && (
        <>
          {mask && !isDisabled && (
            <InputMask
              {...{
                mask,
                value,
                onChange,
                onBlur,
                onFocus,
                disabled: isDisabled,
                readOnly: isDisabled
              }}
            >
              {inputProps => {
                return (
                  <Form.Control
                    {...inputProps}
                    {...{ min, max, maxLength }}
                    {...{ plaintext, readOnly, autoComplete, placeholder }}
                    size={size}
                    type={type}
                    isInvalid={invalid && (modified || touched)}
                  />
                );
              }}
            </InputMask>
          )}
          {(!mask || (mask && isDisabled)) && (
            <Form.Control
              {...{ name, value, onChange, onBlur, type, size, autoComplete, placeholder }}
              {...{ min, max }}
              {...{ plaintext, readOnly, maxLength }}
              disabled={isDisabled}
              isInvalid={invalid && (modified || touched)}
            />
          )}
        </>
      )}
      {helpText && !error && <Form.Text>{helpText}</Form.Text>}
      {!icon && !unit && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  );
};
