export enum jobStatus {
  UNKNOWN = 'unknown',
  NEW = 'new',
  ACTIVE = 'active',
  IN_PROGRESS = 'in_progress',
  IN_QUESTION = 'in_question',
  READY_TO_BILL = 'ready_to_bill',
  BILLED = 'billed',
  COMPLETE = 'completed',
  CLOSED = 'closed',
  CANCELLED = 'cancelled'
}

export const jobStatusLabels: Record<jobStatus, string> = {
  [jobStatus.UNKNOWN]: 'Unknown',
  [jobStatus.NEW]: 'New',
  [jobStatus.ACTIVE]: 'Active',
  [jobStatus.IN_PROGRESS]: 'In Progress',
  [jobStatus.IN_QUESTION]: 'In Question',
  [jobStatus.READY_TO_BILL]: 'Ready To Bill',
  [jobStatus.BILLED]: 'Billed',
  [jobStatus.COMPLETE]: 'Complete',
  [jobStatus.CLOSED]: 'Closed',
  [jobStatus.CANCELLED]: 'Cancelled'
};
