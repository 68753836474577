import { omit } from 'lodash';

import { Employee as EmployeeInterface } from '@shared/types';
import { EmployeeFormValues } from './types';

export const normalizeEmployeeForPost = (employeeForm: EmployeeFormValues): EmployeeInterface => ({
  ...omit(employeeForm, ['photo', 'photoPath', 'hasCdlLicense', 'hasValidDriversLicense']),
  hasValidDriversLicense: employeeForm.hasValidDriversLicense === 'true',
  hasCdlLicense: employeeForm.hasCdlLicense === 'true'
});
