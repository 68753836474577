import { downloadBlob } from './download';

const csvEncode = (value?: string) => {
  const newValue = value?.replace(/"/g, '""');
  return newValue?.includes(',') ? `"${newValue}"` : newValue + '';
};
export class CSV {
  #rows: string[][];
  constructor(...headers: string[]) {
    this.#rows = [headers.map(csvEncode)];
  }

  push(...rows: string[][]): void {
    this.#rows.push(...rows.map(row => row.map(csvEncode)));
  }

  toBlob(): Blob {
    return new Blob([this.#rows.map(values => values.join(',')).join('\n')], { type: 'text/csv' });
  }

  downloadAs(filename: string): void {
    downloadBlob(this.toBlob(), filename);
  }
}
