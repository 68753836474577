import { serviceType } from './serviceType';

enum materialTypes {
  STONE = 'stone',
  SURFACE = 'surface',
  BINDER = 'binder',
  BASE = 'base',
  CONCRETE = 'concrete',
  SEALER = 'sealer',
  CRACKFILLER = 'crackfiller'
}

const { STONE, SURFACE, BINDER, BASE, CONCRETE, SEALER, CRACKFILLER } = materialTypes;

export const serviceTypeToMaterialTypes = Object.values(serviceType).reduce((mapping, slug) => {
  let collection: materialTypes[] = [];

  switch (slug) {
    case serviceType.EXCAVATE:
      collection = [STONE];
      break;
    case serviceType.PREP:
      collection = [STONE, SURFACE, BINDER, BASE];
      break;
    case serviceType.PAVE:
      collection = [SURFACE, BINDER, BASE];
      break;
    case serviceType.CONCRETE:
      collection = [CONCRETE, STONE];
      break;
    case serviceType.SEAL:
      collection = [SEALER, CRACKFILLER];
      break;
    default:
      collection = [];
  }

  return {
    ...mapping,
    [slug]: collection
  };
}, {});
