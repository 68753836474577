// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import React from 'react';
import ReactDOM from 'react-dom';
import { FieldState } from 'final-form';
import { Provider } from 'react-redux';
import { store } from '@shared/store';
import { BrowserRouter as Router } from 'react-router-dom';
import 'channels';
import './application.scss';

// this captures the icon file in the manifest so the Rails template can see it
import 'assets/icons/icons.svg';
import 'assets/images/paveday-favicon.png';
import { AbilityProvider } from '@shared/authorization';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://d7093137fe524e0eaffb9044aea98c72@sentry.missiondata.com/52',
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  debug: true, // TODO: production release disable
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

Rails.start();
ActiveStorage.start();

declare module 'react-final-form-arrays' {
  export type Fields<FieldValue> = {
    forEach: (iterator: (name: string, index: number) => void) => void;
    insert: (index: number, value: FieldValue) => void;
    map: <R>(iterator: (name: string, index: number) => R) => R[];
    move: (from: number, to: number) => void;
    update: (index: number, value: FieldValue) => void;
    name: string;
    pop: () => FieldValue;
    push: (value: FieldValue) => void;
    remove: (index: number) => FieldValue;
    shift: () => FieldValue;
    swap: (indexA: number, indexB: number) => void;
    unshift: (value: FieldValue) => void;
    value: FieldValue[];
  } & FieldState<FieldValue[]>;
}

const render = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const App = require('../components/App').default;
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <AbilityProvider>
          <App />
        </AbilityProvider>
      </Router>
    </Provider>,
    document.getElementById('app-container')
  );
};

document.addEventListener('DOMContentLoaded', () => {
  render();
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render);
}
