import { TODO } from '@shared/utils';
import React from 'react';
import { Dropdown, DropdownButton, DropdownButtonProps } from 'react-bootstrap';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';

import { IconWithText } from '@components/common';
import { uniq } from 'lodash';

export const exportFormat = {
  PDF: 'PDF',
  CSV: 'CSV',
  XLSX: 'XLSX'
} as const;

type ExportFormat = typeof exportFormat[keyof typeof exportFormat];
interface ExportButtonProps extends Omit<DropdownButtonProps, 'title'> {
  formats?: ExportFormat[];
  onExportFormatClick?: (format: ExportFormat, event: React.MouseEvent<DropdownItemProps>) => void;
}

export const ExportButton = ({
  formats = ['CSV'],
  onExportFormatClick = format => {
    TODO(`Export ${format}`);
  },
  ...dropdownButtonProps
}: ExportButtonProps): JSX.Element => {
  return (
    <DropdownButton
      variant="outline-dark"
      title={
        <IconWithText iconName="download" className="d-inline-flex">
          Export
        </IconWithText>
      }
      {...dropdownButtonProps}
    >
      {uniq(formats).map(format => (
        <Dropdown.Item
          key={format}
          as="button"
          onClick={event => onExportFormatClick(format, event)}
        >
          {format}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};
