import React from 'react';
import { Form } from 'react-bootstrap';
import { FieldRenderProps } from 'react-final-form';

export interface CheckboxFieldProps extends FieldRenderProps<boolean, HTMLInputElement> {
  label?: string;
  formGroupLabel?: string;
  isDisabled?: boolean;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  input,
  label,
  formGroupLabel,
  isDisabled,
  ...props
}) => (
  <Form.Group controlId={input.name}>
    {formGroupLabel && <Form.Label>{formGroupLabel}</Form.Label>}
    <Form.Check
      type="checkbox"
      id={`checkbox-${input.name}`}
      {...input}
      label={label}
      disabled={isDisabled}
      checked={input.value}
      {...props}
    />
  </Form.Group>
);
