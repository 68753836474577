export enum materialType {
  ASPHALT = 'Asphalt',
  BINDER = 'Binder',
  ROCK = 'Rock',
  SEALER = 'Sealer'
}

export enum materialTypes {
  STONE = 'stone',
  SURFACE = 'surface',
  BINDER = 'binder',
  BASE = 'base',
  CONCRETE = 'concrete',
  SEALER = 'sealer',
  CRACKFILLER = 'crackfiller'
}
