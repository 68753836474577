import { has, isObject } from 'lodash-es';
import { RailsObject } from './RailsObject';
import { User } from '@shared/types/User';
import { Division } from './Division';

export interface LeaseTruck extends RailsObject {
  vendorName: string;
  hourlyRate: number;
  division: Division | undefined;
  userId: number;
  contactName?: string;
  cellphone?: string;
  email?: string;
  isDiscarded: boolean;
  photo?: string | null;
  photoPath?: string;
}

export interface DetailedLeaseTruck extends LeaseTruck {
  user: User;
}

export const isLeaseTruck = (obj: unknown): obj is LeaseTruck => {
  return isObject(obj) && has(obj, 'vendorName');
};
