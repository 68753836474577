import React from 'react';
import classnames from 'classnames';
import { Badge, BadgeProps } from 'react-bootstrap';

import { ServiceType, serviceTypeNames } from '@shared/constants';
import { getServiceDetailColor } from '@shared/utils';

export interface ServiceTypeBadgeProps extends BadgeProps {
  type: ServiceType | string | '';
  count?: number;
}

export const ServiceTypeBadge: React.FC<ServiceTypeBadgeProps> = ({
  type,
  count,
  className,
  ...props
}) => {
  return (
    <Badge className={classnames(`badge--${getServiceDetailColor(type as string)}`, className)} {...props}>
      {serviceTypeNames[type]}
      {count !== undefined && ` (${count})`}
    </Badge>
  );
};
