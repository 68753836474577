import { createSlice, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { AlertsState } from './types';

const initialState: AlertsState = {
  errors: []
};

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setGlobalErrors(state, action: PayloadAction<string[]>) {
      state.errors = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(isRejected, (state, action) => {
      state.errors = action.error.message?.split('|') ?? [];
    });
  }
});

export const { setGlobalErrors } = alertsSlice.actions;
export default alertsSlice.reducer;
