import { Icon } from '@components/common';
import { ACCEPTED_IMAGE_TYPES } from '@shared/constants';
import { createPreviewImage } from '@shared/utils';
import { isString } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { Button, Col, Form, ImageProps, Row, Image, Overlay, Tooltip } from 'react-bootstrap';
import { FieldRenderProps } from 'react-final-form';

interface MultiImageFieldProps extends FieldRenderProps<(string | File)[], HTMLInputElement> {
  imageProps: ImageProps & { width: number; height: number };
  getImagePath: (photo: string) => string | undefined;
  className?: string;
  label?: string;
}

export const MultiImageField = ({
  label,
  input: { name, value, onChange },
  imageProps,
  getImagePath
}: MultiImageFieldProps): JSX.Element => {
  const [previews, setPreviews] = useState(new Map<File, string>());
  const [previewError, setPreviewError] = useState<string | undefined>(undefined);
  const tooltipTarget = useRef<HTMLDivElement>(null);

  const { width, height } = imageProps;

  const handleInputChange = useCallback(
    async ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
      setPreviewError(undefined);
      if (currentTarget.files) {
        try {
          const files = Array.from(currentTarget.files);

          const newPreviews = await Promise.all(
            files.map<Promise<[File, string]>>(async file => {
              return [file, await createPreviewImage(file, { width, height, text: file.name })];
            })
          );
          setPreviews(new Map(Array.from(previews).concat(newPreviews)));
          onChange(value.concat(...currentTarget.files));
        } catch (error) {
          setPreviewError(error?.message ?? error + '');
        }
      }
    },
    [onChange, previews, value, width, height]
  );

  return (
    <Form.Group controlId={name}>
      {label && <Form.Label>{label}</Form.Label>}
      <Row>
        {value.map((image, index) => {
          const src = isString(image) ? getImagePath(image) : previews.get(image);
          return (
            <Col key={`${name}[${index}]`} sm="auto" className="mb-3">
              <div className="position-relative border rounded shadow-sm">
                <Button
                  className="position-absolute"
                  style={{ right: '0px', bottom: '0px', zIndex: 10, opacity: 0.5 }}
                  size="sm"
                  variant="danger"
                  onClick={() => {
                    onChange(value.filter((image, otherIndex) => otherIndex !== index));
                  }}
                >
                  <Icon name="trash" />
                </Button>
                <Image {...imageProps} src={src} />
              </div>
            </Col>
          );
        })}
        <Col sm="auto" className="mb-3">
          <div
            ref={tooltipTarget}
            className="border rounded shadow-sm position-relative"
            style={{ cursor: 'pointer', width, height }}
          >
            <input
              type="file"
              id={name}
              className="d-none"
              multiple
              accept={ACCEPTED_IMAGE_TYPES.join(',')}
              onChange={handleInputChange}
            />
            <label htmlFor={name} className="d-flex w-100 h-100" style={{ cursor: 'pointer' }}>
              <span className="d-block bg-secondary text-white m-auto py-1 px-2">
                <Icon name="plus" />
              </span>
            </label>
            <Overlay target={tooltipTarget.current} show={!!previewError} placement="right-end">
              {props => (
                <Tooltip id={`${name}_Tooltip`} {...props}>
                  {previewError}
                </Tooltip>
              )}
            </Overlay>
          </div>
        </Col>
      </Row>
    </Form.Group>
  );
};
