import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  BillingRequestCreateResponse,
  BillingRequestPatch,
  DetailedJob,
  DetailedJobReport,
  Filter,
  ListedJob,
  PagePrams,
  PaginatedData,
  ServiceDetail
} from "@shared/types";
import { request } from '@shared/api';
import { byId, queryStringify } from '@utils';
import { JobPatch } from '@shared/store';
import { toString, zip } from 'lodash';
import { BillingRequest } from '@shared/types/BillingRequest';

export const getJobs = createAsyncThunk(
  'jobs/getJobs',
  async ({
    page,
    filter,
    ...params
  }: { page: PagePrams; filter?: Record<string, unknown> } & Record<string, unknown>) => {
    return await request<PaginatedData<ListedJob>>(
      'get',
      `jobs?${queryStringify({ page, filter, ...params })}`
    );
  }
);

export const getJobFilters = createAsyncThunk('jobs/getFilters', async () => {
  return await request<Filter[]>('get', `jobs/filters`);
});

export const getJobDetails = createAsyncThunk('jobs/getJobDetails', async (id: number) => {
  return await request<DetailedJob>('get', `jobs/${id}`);
});

const uploadPhotos = <T>(job: JobPatch, updatedJob: DetailedJob) => {
  const jobAssignmentsWithPendingUpload = (job.jobAssignments ?? []).filter(jobAssignment => {
    const oldPhotos = updatedJob.jobAssignments.find(byId(jobAssignment.id))?.jobReport?.photos;
    const currentPhotos = jobAssignment?.jobReport?.photos;
    return zip(oldPhotos, currentPhotos).some(
      ([oldPhoto, currentPhoto]) => oldPhoto !== currentPhoto
    );
  });

  if (jobAssignmentsWithPendingUpload.length === 0) return updatedJob;

  const formData = new FormData();
  formData.append('job[id]', job.id.toString());
  jobAssignmentsWithPendingUpload.forEach(jobAssignment => {
    const updatedJobAssignment = updatedJob.jobAssignments.find(byId(jobAssignment.id));
    const oldPhotos = updatedJobAssignment?.jobReport?.photos;
    const currentPhotos = jobAssignment?.jobReport?.photos;
    if (
      zip(oldPhotos, currentPhotos).some(([oldPhoto, currentPhoto]) => oldPhoto !== currentPhoto)
    ) {
      formData.append('job[job_assignments][][id]', jobAssignment.id.toString());
      formData.append(
        'job[job_assignments][][job_report][id]',
        toString(jobAssignment.jobReport?.id)
      );
      jobAssignment.jobReport?.photos?.forEach(photo => {
        formData.append('job[job_assignments][][job_report][photos][]', photo);
      });
    }
  });
  return request<T, FormData>('put', `jobs/${job.id}`, formData);
};

export const updateJobDetails = createAsyncThunk('jobs/updateJob', async (job: JobPatch) => {
  const updatedJob = await request<DetailedJob, { job: JobPatch }>('patch', `jobs/${job.id}`, {
    job: job
  });

  return uploadPhotos<DetailedJob>(job, updatedJob);
});

const uploadJobReportPhotos = async (
  jobReport: DetailedJobReport,
  updatedJobReport: DetailedJobReport
) => {
  const formData = new FormData();

  formData.append('job_report[id]', (updatedJobReport?.id ?? 0).toString());
  formData.append('job_report[completed_by_id]', updatedJobReport.completedById?.toString() ?? '');

  const oldPhotos = jobReport.photos;
  const newPhotos = updatedJobReport.photos;

  if (zip(oldPhotos, newPhotos).some(([oldPhoto, newPhoto]) => oldPhoto !== newPhoto)) {
    jobReport.photos.forEach(photo => {
      formData.append('job_report[photos][]', photo);
    });
  }

  return request<DetailedJobReport, FormData>(
    'put',
    `job_reports/${updatedJobReport.id}`,
    formData
  );
};

export const createJobReport = createAsyncThunk(
  'jobs/createJobReport',
  async (jobReport: DetailedJobReport) => {
    const createdJobReport = await request<DetailedJobReport, { jobReport: DetailedJobReport }>(
      'post',
      `job_reports`,
      { jobReport }
    );

    return uploadJobReportPhotos(jobReport, createdJobReport);
  }
);

export const updateJobReport = createAsyncThunk(
  'jobs/updateJobReport',
  async (jobReport: DetailedJobReport) => {
    const updatedJobReport = await request<DetailedJobReport, { jobReport: DetailedJobReport }>(
      'patch',
      `job_reports/${jobReport.id}`,
      { jobReport }
    );

    return uploadJobReportPhotos(jobReport, updatedJobReport);
  }
);

const uploadServiceDetailPhotos = (
  serviceDetail: ServiceDetail,
  updatedServiceDetail: ServiceDetail
) => {
  const formData = new FormData();

  formData.append('service_detail[id]', (updatedServiceDetail?.id ?? 0).toString());

  serviceDetail.photos.forEach(photo => {
    formData.append('service_detail[photos][]', photo);
  });

  return request<ServiceDetail, FormData>(
    'put',
    `service_details/${updatedServiceDetail.id}`,
    formData
  );
};

export const createServiceDetail = createAsyncThunk(
  'jobs/createServiceDetail',
  async (serviceDetail: ServiceDetail) => {
    const createdServiceDetail = await request<ServiceDetail, { serviceDetail: ServiceDetail }>(
      'post',
      `service_details`,
      {
        serviceDetail
      }
    );

    return uploadServiceDetailPhotos(serviceDetail, createdServiceDetail);
  }
);

export const updateServiceDetail = createAsyncThunk(
  'jobs/updateServiceDetail',
  async (serviceDetail: ServiceDetail) => {
    const updatedServiceDetail = await request<ServiceDetail, { serviceDetail: ServiceDetail }>(
      'patch',
      `service_details/${serviceDetail.id}`,
      {
        serviceDetail
      }
    );

    return uploadServiceDetailPhotos(serviceDetail, updatedServiceDetail);
  }
);

export const updateListedJob = createAsyncThunk('jobs/updateListedJob', async (job: JobPatch) => {
  return await request<DetailedJob, { job: JobPatch }>('patch', `jobs/${job.id}`, {
    job
  });
});

export const updateJobBillingRequests = createAsyncThunk(
  'jobs/updateJobBillingRequests',
  async (billingRequests: BillingRequest[]) => {
    // we need to save all of the billing requests together
    return await Promise.all(
      billingRequests.map(billingRequest => {
        return request<BillingRequest, { billingRequest: BillingRequest }>(
          'put',
          `billing_requests/${billingRequest.id}`,
          { billingRequest }
        );
      })
    );
  }
);

export const createJobBillingRequests = createAsyncThunk(
  'jobs/createJobBillingRequests',
  async (billingRequests: BillingRequestPatch[]) => {
    return await Promise.all(
      billingRequests.map(billingRequest => {
        return request<BillingRequestCreateResponse, { billingRequest: BillingRequestPatch }>(
          'post',
          'billing_requests',
          { billingRequest }
        );
      })
    );
  }
);
