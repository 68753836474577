import React from 'react';
import classnames from 'classnames';
import { Col, Form, Row } from 'react-bootstrap';
import { TextField, TextFieldProps, TextFieldValueType } from '.';

interface InlineTextFieldProps<T extends TextFieldValueType> extends TextFieldProps<T> {
  labelColSize?: number | 'auto';
  wrapperClassName?: string;
}

export const InlineTextField = <T extends TextFieldValueType = string>({
  label,
  labelColSize,
  wrapperClassName,
  ...textFieldProps
}: InlineTextFieldProps<T>): JSX.Element => {
  return (
    <Form.Group as={Row} noGutters className={classnames(wrapperClassName, 'flex-column')}>
      {label && (
        <Form.Label column={textFieldProps.size} sm={labelColSize}>
          {label}
        </Form.Label>
      )}
      <Col>
        <TextField {...textFieldProps} as={Row} className="mx-0 my-0" />
      </Col>
    </Form.Group>
  );
};
