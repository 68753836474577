import { AvatarProps, AvatarSizes, InfoBugProps } from '@components/common';
import { Employee, employeeLabel } from '@shared/types';

export const getEmployeeColor = (employee?: Pick<Employee, 'employeeType'>): string => {
  if (!employee) return 'gray';
  return employee.employeeType === 'foreman' ? 'blue' : 'yellow';
};

export const getEmployeeDisplayedName = ({
  displayedName,
  fullName
}: Pick<Employee, 'displayedName' | 'fullName'>): string => {
  return displayedName || fullName;
};

export const getEmployeeInitials = (
  firstAndLast: Pick<Employee, 'firstName' | 'lastName'> | undefined
): string => {
  if (!firstAndLast) {
    return '';
  }

  const { firstName, lastName } = firstAndLast;
  return `${firstName?.[0]}${lastName?.[0]}`;
};

export const getEmployeeAvatar = (
  employee?: Pick<Employee, 'firstName' | 'lastName' | 'photoPath' | 'employeeType'>
): AvatarProps => {
  if (!employee)
    return {
      color: 'gray',
      initials: '?',
      url: undefined
    };

  const { firstName, lastName, photoPath } = employee;

  const initials = photoPath ? undefined : getEmployeeInitials({ firstName, lastName });
  const color = getEmployeeColor(employee);
  const url = photoPath === null ? undefined : photoPath;

  return {
    initials,
    color,
    url
  };
};

interface GetEmployeeInfoBugProps {
  employee?: Employee;
  avatarSize?: AvatarSizes;
}

export const getEmployeeInfoBug = ({
  employee,
  avatarSize
}: GetEmployeeInfoBugProps): InfoBugProps => {
  const label =  employeeLabel(employee)
  const color = getEmployeeColor(employee);

  return {
    label,
    color,
    avatar: {
      ...getEmployeeAvatar(employee),
      size: avatarSize
    }
  };
};
