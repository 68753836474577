import classNames from 'classnames';
import React from 'react';

import './styles/subheading.scss';

export interface SubheadingProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  /** The title of the page. */
  title: string;
  /** text-transform to be applied to the title */
  titleTransform?: 'uppercase' | 'lowercase' | 'capitalize';
  /** Applies a border to the bottom of the subheading. */
  bordered?: boolean;
  /** Set the type of wrapper tag around the `subheading`. Primarily useful for seleting h-tags. */
  as?: React.ElementType;
  /** Additional controls or content to render on the right edge of the subheading. */
  actions?: React.ReactNode;
}

export const Subheading: React.FC<SubheadingProps> = ({
  title,
  titleTransform = 'uppercase',
  as: Component = 'h2',
  bordered = true,
  actions,
  className,
  ...props
}) => (
  <Component
    className={classNames(
      'subheading',
      {
        'subheading--bordered': bordered
      },
      className
    )}
    {...props}
  >
    <span className={classNames('subheading__title', `text-${titleTransform}`)}>{title}</span>
    {actions && <span className="subheading__actions">{actions}</span>}
  </Component>
);
