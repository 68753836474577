import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

export const AdminLayout: React.FC = ({ children }) => {
  return (
    <div className="admin-layout">
      <Nav variant="tabs" className="mt-3">
        <LinkContainer to="/admin/users">
          <Nav.Link>Users</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/admin/resource-management">
          <Nav.Link>Resource Management</Nav.Link>
        </LinkContainer>
      </Nav>
      {children}
    </div>
  );
};
