import React from 'react';
import classnames from 'classnames';
import { Form } from 'react-bootstrap';
import { FieldRenderProps } from 'react-final-form';

import { SelectOption } from '@shared/types';

export type SelectFieldValueType = string | number | string[] | undefined;

export interface SelectFieldProps<T extends SelectFieldValueType = string>
  extends FieldRenderProps<T, HTMLSelectElement> {
  options: SelectOption<T>[];
  isDisabled?: boolean;
  autoWidth?: boolean;
  blankOption?: string;
  size?: 'sm' | 'lg';
  label?: string;
  helpText?: React.ReactNode;
}

const makeOption = (
  { value, label, disabled }: SelectOption<SelectFieldValueType>,
  index?: number
): JSX.Element => (
  <option key={index !== undefined ? `Option_${index}` : undefined} {...{ value, disabled }}>
    {label}
  </option>
);

export const SelectField = <T extends SelectFieldValueType = string>({
  input: { name, value, onChange },
  meta: { invalid, error, modified, touched },
  label,
  size,
  options,
  isDisabled,
  blankOption,
  autoWidth = false,
  helpText,
  ...otherProps
}: SelectFieldProps<T>): JSX.Element => (
  <Form.Group controlId={name} {...otherProps}>
    {label && <Form.Label>{label}</Form.Label>}
    <Form.Control
      as="select"
      {...{ name, value, onChange, disabled: isDisabled, size }}
      isInvalid={invalid && (modified || touched)}
      className={classnames({
        'auto-width': autoWidth
      })}
    >
      {blankOption &&
        makeOption({
          label: blankOption,
          value: ''
        })}
      {options.map((option, index) => {
        if (option.children) {
          return (
            <optgroup label={option.label} key={`OptGroup_${index}`}>
              {option.children.map((child, childIndex) => makeOption(child, childIndex))}
            </optgroup>
          );
        }

        return makeOption(option, index);
      })}
    </Form.Control>
    {helpText && !error && <Form.Text>{helpText}</Form.Text>}
    {error && (
      <Form.Control.Feedback type="invalid" className="d-inline">
        {error}
      </Form.Control.Feedback>
    )}
  </Form.Group>
);
