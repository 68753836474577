import React from 'react';
import { Button, Carousel, Image, Modal } from 'react-bootstrap';

import './styles/image-carousel-modal.scss';
import { Icon } from '@components/common';

export interface ImageCarouselModalProps {
  /** Content to render inside the modal. */
  images: [title: string, src: string] | undefined;

  /** Sets the visibility of the modal. */
  isVisible: boolean;

  /**
   * Displays a loading state inside the modal for situations where content
   * is loaded from a remote source.
   */
  isLoadingContent?: boolean;

  /** Sets the message below the loading animation icon. */
  loadingContentMessage?: string;

  /** Callback fired when the **Cancel** button is clicked. */
  onCancel(): void;

  /** Callback fired when the modal is hidden. */
  onHide(): void;

  /** Sets the size of the modal. */
  size: 'sm' | 'lg' | 'xl';

  /** Sets a custom title for the modal. */
  title?: string;
}

export const ImageCarouselModal = ({
                                     images,
                                     isVisible,
                                     onCancel,
                                     onHide,
                                     size,
                                     title
                                   }: ImageCarouselModalProps): JSX.Element => (
  <Modal bsSize={size} onHide={onHide} show={isVisible} dialogClassName='confirmation-modal'>
    <Modal.Header>
      <Modal.Title as='h2'>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Carousel prevIcon={<Icon name='chevron-left' size='huge' />}
                nextIcon={<Icon name='chevron-right' size='huge' />}
                indicators={false}
                wrap={false}
      >
        {images?.map((photo) => (
            <Carousel.Item key={photo.title}>
              <Carousel.Caption>
                <span>{photo.title}</span>
              </Carousel.Caption>
              <Image src={photo.src} thumbnail />
              <span></span>
            </Carousel.Item>
          )
        )}
      </Carousel>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='secondary' onClick={onCancel}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

ImageCarouselModal.defaultProps = {
  job: null,
  isLoadingContent: false,
  loadingContentMessage: 'Loading…',
  title: 'Confirmation'
};
