import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { RootState } from '@shared/store';

import { AnnotatedTruckReport } from '@shared/types';
import { TruckReportsState } from './types';
import { getTruckReports, createTruckReport, updateTruckReport, deleteTruckReport } from './thunks';

const initialState: TruckReportsState = {
  allTruckReports: [],
  totalCount: 0,
  isLoaded: false,
  isLoading: false,
  isSubmitting: false,
  activeTruckReport: null,
  errorMessages: []
};

const truckReportsSlice = createSlice({
  name: 'truckReports',
  initialState,
  reducers: {
    setActiveTruckReport(state, action: PayloadAction<AnnotatedTruckReport | null>) {
      state.activeTruckReport = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getTruckReports.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(getTruckReports.fulfilled, (state, action) => {
      state.allTruckReports = action.payload.data;
      state.totalCount = action.payload.totalCount;
      state.isLoading = false;
      state.isLoaded = true;
    });

    builder.addCase(getTruckReports.rejected, (state, action) => {
      state.errorMessages = action.error.message?.split('|') ?? [];
    });

    builder.addCase(createTruckReport.pending, state => {
      state.isSubmitting = true;
    });

    builder.addCase(createTruckReport.fulfilled, (state, action) => {
      state.allTruckReports.push(action.payload);
      state.activeTruckReport = null;
      state.isSubmitting = false;
    });

    builder.addCase(createTruckReport.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }

      state.isSubmitting = false;
    });

    builder.addCase(updateTruckReport.pending, state => {
      state.isSubmitting = true;
    });

    builder.addCase(updateTruckReport.fulfilled, (state, action) => {
      const truckReportIndex = state.allTruckReports.findIndex(
        employee => employee.id === action.payload.id
      );

      if (truckReportIndex !== -1) {
        state.allTruckReports[truckReportIndex] = action.payload;
      }

      state.activeTruckReport = null;
      state.isSubmitting = false;
    });

    builder.addCase(updateTruckReport.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }

      state.isSubmitting = false;
    });

    builder.addCase(deleteTruckReport.pending, state => {
      state.isSubmitting = true;
    });

    builder.addCase(deleteTruckReport.fulfilled, (state, action) => {
      state.allTruckReports = state.allTruckReports.filter(user => user.id !== action.meta.arg.id);
      state.activeTruckReport = null;
      state.isSubmitting = false;
    });

    builder.addCase(deleteTruckReport.rejected, (state, action) => {
      state.errorMessages = action.error.message?.split('|') ?? [];
      state.isSubmitting = false;
    });
  }
});

export const { setActiveTruckReport } = truckReportsSlice.actions;
export default truckReportsSlice.reducer;

export const useTruckReportsSelector = (): TruckReportsState =>
  useSelector<RootState, TruckReportsState>(state => state.truckReports);
