import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { request } from '@shared/api';
import { DetailedJob } from '@shared/types';
import { setJobDetails, useAppDispatch } from '@shared/store';
import { useHistory } from 'react-router-dom';

export const GoToJobModal = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [value, setValue] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const formControlRef = useRef<HTMLInputElement>(null);

  const enableModal = useCallback(() => {
    setIsVisible(true);
    setError(null);
    requestAnimationFrame(() => {
      formControlRef.current?.focus();
    });
  }, [setIsVisible]);

  const handleGlobalKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'j') {
        event.preventDefault();
        enableModal();
      }
    },
    [enableModal]
  );

  const handleOnChange = useCallback(
    event => {
      if (event.target.value.length > 8) return;
      setValue(event.target.value);
    },
    [setValue]
  );

  const handleGoToJob = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setIsWorking(true);
      setError(null);

      const formData = new FormData(event.currentTarget);
      const jobNumber = formData.get('jobNumber');

      try {
        const response = await request<DetailedJob>(
          'get',
          `jobs/from_job_number?job_number=${jobNumber}`
        );
        dispatch(setJobDetails(response));
        setIsVisible(false);
        setIsWorking(false);
        setValue('');
        history.push(`/jobs/${response.id}`);
      } catch (error) {
        setError(error.status === 404 ? error.message : 'An error occurred. Please try again.');
        setIsWorking(false);
        formControlRef.current?.focus();
      }
    },
    [dispatch, history, setIsVisible, setIsWorking, setError, setValue]
  );

  const handleShowModal = useCallback(() => {
    enableModal();
  }, [enableModal]);

  useEffect(() => {
    document.addEventListener('keydown', handleGlobalKeyPress);
    window.addEventListener('go-to-job', handleShowModal);

    return () => {
      document.removeEventListener('keydown', handleGlobalKeyPress);
      window.removeEventListener('go-to-job', handleShowModal);
    };
  }, [handleGlobalKeyPress, handleShowModal]);

  return (
    <Modal show={isVisible} onHide={() => setIsVisible(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Go to Job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleGoToJob}>
          <Row className="align-items-end">
            <Col>
              <Form.Group controlId="jobNumber" className="mb-0 pb-5 position-relative">
                <Form.Label>Job Number</Form.Label>
                <Form.Control
                  name="jobNumber"
                  type="number"
                  placeholder="Job Number"
                  value={value}
                  disabled={isWorking}
                  ref={formControlRef}
                  isInvalid={!!error}
                  onChange={handleOnChange}
                  required
                />
                <Form.Control.Feedback type="invalid" className="position-absolute">
                  {error}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Button variant="primary" type="submit" disabled={isWorking} className="mb-5">
                Go
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
