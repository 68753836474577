import { useSelector } from "react-redux";
import { BillingGroupsState } from "./types";
import { RootState } from "@shared/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BillingGroup } from "@shared/types/BillingGroup";
import { getBillingGroups } from "./thunks";

const initialState: BillingGroupsState = {
    billingGroups: [],
    isLoading: true,
    isLoaded: false,
    isSubmitting: false,
    errorMessages: []
}

const billingGroupsSlice = createSlice({
    name: 'billilngGroups',
    initialState,
    reducers: {
        setBillingGroups(state, action: PayloadAction<BillingGroup[]>) {
            state.billingGroups = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(getBillingGroups.pending, state => {
            state.isLoading = true;
        });

        builder.addCase(getBillingGroups.fulfilled, (state, action) => {
            const data = action.payload

            state.billingGroups = data;
            state.isLoading = false;
            state.isLoaded = true
        });
    }
});

export const {
    setBillingGroups
} = billingGroupsSlice.actions;

export default billingGroupsSlice.reducer;

export const useBillingGroupsSelector = (): BillingGroupsState =>
    useSelector<RootState, BillingGroupsState>(state => state.billingGroups);
