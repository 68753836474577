import React from 'react';
import classnames from 'classnames';
import { Image } from 'react-bootstrap';
import { isUndefined } from 'lodash';

import './styles/avatar.scss';

export type AvatarSizes = 'xxs' | 'xs'| 'small' | 'medium' | 'large' | 'xl';
export interface AvatarProps {
  /** URL of an image to display. */
  url?: string;
  /** A pair of initials to display if no image is provided. */
  initials?: string;
  /** Size of the avatar. */
  size?: AvatarSizes;
  /** A background color for the avatar (if no image is used). */
  color?: string;
}

export const Avatar: React.FC<AvatarProps> = ({
  url,
  color = '',
  initials = 'AA',
  size = 'medium'
}: AvatarProps): JSX.Element => {
  const showInitials = isUndefined(url) && !isUndefined(initials);

  return (
    <div
      className={classnames('avatar', `avatar--${size}`, {
        'avatar--initials': showInitials,
        [`avatar--${color}`]: color.length > 0
      })}
    >
      {!showInitials && <Image src={url} roundedCircle />}
      {showInitials && <span>{initials}</span>}
    </div>
  );
};
