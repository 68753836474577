import { isUndefined, omit } from 'lodash';

import { Truck as TruckInterface, LeaseTruck as LeaseTruckInterface } from '@shared/types';
import { TruckFormValues, LeaseTruckFormValues } from './types';

export const normalizeTruckForPost = (truckForm: TruckFormValues): TruckInterface => ({
  ...omit(truckForm, ['photo', 'photoPath', 'type']),
  truckType: !isUndefined(truckForm.type)
    ? (truckForm.type as 'libs_truck' | 'libs_triaxles')
    : truckForm.truckType
});

export const normalizeLeaseTruckForPost = (
  truckForm: LeaseTruckFormValues
): LeaseTruckInterface => ({
  ...omit(truckForm, ['photo', 'photoPath', 'type'])
});
