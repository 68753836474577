export const formatPhoneNumber = (
  phoneNumber: string,
  format: 'E.164' | 'Numeric' | 'US'
): string => {
  const numbersOnly = phoneNumber.replace(/[^0-9]/g, '');
  if (format === 'Numeric') return numbersOnly;
  if (format === 'US') {
    return numbersOnly.length < 11
      ? numbersOnly.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      : numbersOnly.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
  }
  return numbersOnly.length < 11 ? `+1${numbersOnly}` : `+${numbersOnly}`;
};
