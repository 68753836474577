import { last } from 'lodash-es';
import { routes } from '@shared/constants';
import { camelizeKeys, decamelizeKeys } from '@shared/utils';
import { SessionService } from '../modules';

export const apiURL = (endpoint = ''): string => `/api/v1/${endpoint}`;

function RequestError(message: string, response: Response) {
  this.name = 'RequestError';
  this.message = message;
  this.stack = new Error().stack;
  this.status = response.status;
}
RequestError.prototype = new Error();

function login(token: string) {
  SessionService.setToken(token);
}

function logout() {
  SessionService.clearSession();
}

export function request<G, P = G>(
  method: 'get' | 'post' | 'patch' | 'put' | 'delete',
  endpoint: string,
  body?: P,
  customHeaders: Record<string, string> = {},
  useJson = true
): Promise<G> {
  const token = SessionService.getToken();
  const headers = new Headers();
  const isMultiPart = body instanceof FormData;

  if (!isMultiPart) {
    headers.append('Content-Type', 'application/json');
  }

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  if (customHeaders) {
    for (const header in customHeaders) {
      headers.append(header, customHeaders[header]);
    }
  }

  const config: RequestInit = {
    method: method.toUpperCase(),
    headers
  };

  if (body) {
    config.body = isMultiPart ? body : JSON.stringify(decamelizeKeys(body));
  }

  return fetch(apiURL(endpoint), config).then(async response => {
    if (response.status === 204) return true;

    if (!useJson) return await response.blob();

    let data;

    try {
      data = await response.json();
    } catch (error) {
      console.log(error);
      data = { errors: [error.toString()] };
    }

    if (method === 'delete' && response.status === 204) {
      // infer an id from the endpoint if we've just deleted something
      return Number(last(endpoint.split('/')));
    }

    if (response.status === 401) {
      logout();
      if (window.location.pathname !== routes.SIGN_IN) {
        const { pathname, search, hash } = window.location;
        SessionService.loginRedirect = pathname + search + hash;
        window.location.href = routes.SIGN_IN;
      }
      return Promise.reject(new Error(data.errors?.join('|') ?? data.error ?? data));
    }

    if (response.status >= 400) {
      /* the thunk will automatically serialize the error, which means the error array
        will turn into a string which we will have to resplit later. By default, a comma
        is used, which is not a reliable breakpoint as a message could conceivably contain
        a comma itself. Here we deliberately join the errors with a pipe, which should never
        appear in any messaging.
        */
      return Promise.reject(
        new RequestError(data.errors?.join('|') ?? data.error ?? data, response)
      );
    }

    const token = response.headers.get('Authorization');
    if (token) {
      login(token.replace('Bearer ', ''));
    }
    return camelizeKeys(data);
  });
}
