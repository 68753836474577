import { startCase } from 'lodash';
import { Division, Material, MaterialType, SelectOption } from '@shared/types';

interface GroupedMaterialOptionsProps {
  sortedMaterials: Record<string, Material[]>;
  materialTypes: MaterialType[];
}

export const groupedMaterialOptions = (
  { sortedMaterials, materialTypes }: GroupedMaterialOptionsProps,
  selectedIds: number[] = [],
  currentDivision: Division | undefined,
  includeFavorites = true,
  prependTypeName = true
): SelectOption<number | undefined | null>[] => {
  const validKeys = materialTypes
    .filter(({ name, isDiscarded }) => {
      if (!isDiscarded) return true;
      return (
        sortedMaterials[name.toLowerCase()]?.find(material =>
          selectedIds.includes(material.id ?? 0)
        ) !== undefined
      );
    })
    .map(({ name }) => name.toLowerCase());

  const optionsCollection = Object.keys(sortedMaterials)
    .filter(key => validKeys.includes(key))
    .map(groupName => {
      const label = startCase(groupName);

      return {
        label,
        value: null,
        children: sortedMaterials[groupName]
          .filter(material => {
            if (!material.isDiscarded) return true;
            return selectedIds.includes(material?.id ?? 0);
          })
          .map(material => ({
            label: `${label} — ${material.name}`,
            value: material.id,
            disabled: selectedIds.includes(material?.id ?? 0)
          }))
      };
    });

  if (includeFavorites) {
    const favorites = Object.values(sortedMaterials).reduce(
      (final, group) => [
        ...final,
        ...group.filter(
          material =>
            material.divisions.some(division => division.id === currentDivision?.id ?? '') &&
            !material.isDiscarded
        )
      ],
      []
    );

    optionsCollection.unshift({
      label: 'Favorites',
      value: null,
      children: favorites.map(({ materialTypeName, name, id }) => ({
        label: prependTypeName ? `${materialTypeName} – ${name}` : name ?? '',
        value: id,
        disabled: selectedIds.includes(id ?? 0)
      }))
    });
  }

  return optionsCollection;
};
