import { isDate } from 'lodash';

const urlSafeParameterize = (val: unknown): string => {
  if(val === null || val  === undefined) {
    return '';
  }

  if(typeof val === 'string' || val === 'number' || val === 'boolean') {
    return encodeURIComponent(val);
  }

  return  encodeURIComponent('' + val);
}

export const queryStringify = (
  object: Record<string, unknown> | Array<unknown>,
  wrapperName?: string
): string => {
  if (Array.isArray(object)) {
    return object
      .map((val, index) => {
        const name = wrapperName ? wrapperName + '[]' : index + '';
        return val != null && typeof val === 'object'
          ? queryStringify(val, name)
          : name + '=' + urlSafeParameterize(val);
      })
      .join('&');
  }
  return Object.entries(object)
    .map(([key, val]) => {
      const name = wrapperName ? wrapperName + '[' + key + ']' : key;
      if (val != null && typeof val === 'object') {
        if (isDate(val)) {
          return name + '=' + val.toISOString();
        }
        return queryStringify(val, name);
      }

      return name + '=' + urlSafeParameterize(val);
    })
    .join('&');
};
