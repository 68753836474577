import React, { useCallback } from 'react';
import { Field } from 'react-final-form';

/**
 * A utility form component that conditionally renders a field based on the value of another.
 * The field that should conditionally render is passed as a child of this component
 */
export const Condition = (
  {
    when,
    is,
    isWithin,
    not = false,
    children
  }: {
    when: string;
    is?: unknown;
    isWithin?: string[];
    not?: boolean;
    children: React.ReactNode;
  }): JSX.Element => {
  const isVisible = useCallback(
    (value) => {
      if (isWithin) {
        const includes = isWithin.includes(value.toString());
        return not ? !includes : includes;
      }
      return (not ?? false) !== (value === is);
    },
    [is, not, isWithin]
  );

  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => isVisible(value) ? children : null}
    </Field>
  );
};
