import { has, isObject } from 'lodash-es';
import { RailsObject } from './RailsObject';

export interface Load extends RailsObject {
  jobAssignmentId: number;
  loadableType: 'Truck' | 'LeaseTruck';
  loadableId: number;
  employeeId: number | null;
  loadableHourlyRate: number;
  loadSpecificNote?: string;
  loadTime: string;
  displayedName: string;
  type?: string;
  updateTruck?: boolean;
  updateEmployee?: boolean;
  libsTruckType: 'libs_truck' | 'libs_triaxles' | null;
}

export const isLoad = (obj: unknown): obj is Load => {
  return isObject(obj) && has(obj, 'loadableId');
};
