export enum userRolesEnum {
  ADMIN = 'admin',
  FOREMAN = 'foreman',
  SALES_REP = 'sales_rep',
  SCHEDULE_VIEWER = 'schedule_viewer',
  PAVING_SCHEDULER = 'paving_scheduler',
  STRIPING_SCHEDULER = 'striping_scheduler',
  CONCRETE_SCHEDULER = 'concrete_scheduler',
  SEALING_SCHEDULER = 'sealing_scheduler',
  LEASE_TRUCK_OPERATOR = 'lease_truck_operator',
  SUPERINTENDENT = 'superintendent'
}

export enum userMobileRolesEnum {
  ADMIN = 'admin',
  FOREMAN = 'foreman',
  SALES_REP = 'sales_rep',
  SCHEDULE_VIEWER = 'schedule_viewer',
  LEASE_TRUCK_OPERATOR = 'lease_truck_operator',
  TRIAXLE_TRUCK_DRIVER = 'triaxle_truck_driver',
  SUPERINTENDENT = 'superintendent'
}

export type UserRole = keyof Record<userRolesEnum, string>;
export type UserMobileRole = keyof Record<userMobileRolesEnum, string>;
