export const downloadUrl = (url: string, filename?: string): void => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename ?? '');
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const downloadBlob = (blob: Blob, filename?: string): void => {
  const url = window.URL.createObjectURL(blob);
  try {
    downloadUrl(url, filename);
  } finally {
    // cleanup
    window.URL.revokeObjectURL(url);
  }
};
