import React, { PropsWithChildren } from 'react';
import * as Sentry from '@sentry/react';

import './styles/error-boundary.scss';
import { FallbackRender } from '@sentry/react/types/errorboundary';

type ErrorBoundaryProps = Record<string, unknown>;

// TODO: figure out how to use _resetError function properly
const fallbackHandler: FallbackRender = ({ error, componentStack }) => (
  <React.Fragment>
    <div className="error-message-wrapper m-4">
      <h1 className="error-message-header text-danger">Uh oh, something went wrong.</h1>
      <details className="error-info-details mt-3">
        <summary className="error-info-details-summary text-danger">{error?.toString()}</summary>
        <pre
          className="error-info-details-stack p-2 mt-3 rounded overflow-auto bg-light shadow-sm"
          style={{ fontFamily: 'monospace' }}
        >
          {componentStack}
        </pre>
      </details>
    </div>
  </React.Fragment>
);

export const ErrorBoundary = ({ children }: PropsWithChildren<ErrorBoundaryProps>): JSX.Element => {
  return <Sentry.ErrorBoundary fallback={fallbackHandler}>{children}</Sentry.ErrorBoundary>;
};
