import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { isString } from 'lodash-es';
import { Image, ImageProps, Overlay, Tooltip } from 'react-bootstrap';
import { FieldRenderProps } from 'react-final-form';

import './styles/image-upload-field.scss';
import { ACCEPTED_IMAGE_TYPES } from '@shared/constants';
import { createPreviewImage } from '@shared/utils';

export interface ImageUploadFieldProps extends FieldRenderProps<string, HTMLInputElement> {
  imageProps?: ImageProps;
}

export const ImageUploadField: React.FC<ImageUploadFieldProps> = ({
  input: { name, value, onChange },
  imageProps = { rounded: true },
  children
}) => {
  const [imagePreview, setImagePreview] = useState(value);
  const [imagePreviewError, setImagePreviewError] = useState('');
  const target = useRef(null);
  const handleInputChange = useCallback(
    async ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
      setImagePreviewError('');

      if (currentTarget.files) {
        try {
          const file = currentTarget.files[0];
          const preview = await createPreviewImage(file);
          setImagePreview(preview);
          onChange(file);
        } catch (error) {
          setImagePreviewError(error.message);
        }
      }
    },
    [setImagePreview, onChange]
  );

  const id = useMemo(() => {
    return `image_field_${name}`;
  }, [name]);

  useEffect(() => {
    if (isString(value)) {
      setImagePreview(value);
    }
  }, [value, setImagePreview]);

  return (
    <div className="image-upload-field position-relative" ref={target}>
      <input
        type="file"
        id={id}
        className="d-none"
        accept={ACCEPTED_IMAGE_TYPES.join(',')}
        onChange={handleInputChange}
      />
      <label htmlFor={id}>
        <Image src={imagePreview} {...imageProps} />
        {children}
      </label>
      <Overlay target={target.current} show={imagePreviewError.length > 0} placement="right-end">
        {props => (
          <Tooltip id={`${id}_Tooltip`} {...props}>
            {imagePreviewError}
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};
